export const HOME_I18N_ENTRIES = {
  home_Data1: {
    es: '<p>Nueva inspección</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  home_Data2: {
    es: '<p>Sube el vídeo de inspección</p><p>y los datos de telemetría</p>',
    en: '<p></p><p></p>',
    pt: '<p></p><p></p>',
    fr: '<p></p><p></p>',
    eus: '<p></p><p></p>',
    cat: '<p></p><p></p>',
  },

  home_Data3: {
    es: '<p>Galería de inspecciones</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  home_Data4: {
    es: '<p>Consulta todas las</p><p>inspecciones realizadas</p>',
    en: '<p></p><p></p>',
    pt: '<p></p><p></p>',
    fr: '<p></p><p></p>',
    eus: '<p></p><p></p>',
    cat: '<p></p><p></p>',
  },

  home_Data5: {
    es: '<p>Inspecciones en proceso</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  home_Data6: {
    es: '<p>Gestión de cuenta</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  home_Data7: {
    es: '<p>Galería de proyectos</p>',
    en: '<p>Project Gallery</p>',
    pt: '<p>Galeria de projetos</p>',
    fr: '<p>Galería de proyectos</p>',
    eus: '<p>Galería de proyectos</p>',
    cat: '<p>Galería de proyectos</p>',
  },

  home_Data8: {
    es: '<p>Mis inspecciones</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  home_Data9: {
    es: '<p>Mostrar todo</p>',
    en: '<p>Show all</p>',
    pt: '<p>Mostrar tudo</p>',
    fr: '<p>Mostrar todo</p>',
    eus: '<p>Mostrar todo</p>',
    cat: '<p>Mostrar todo</p>',
  },

  home_Data10: {
    es: '<p>Actualmente no tienes proyectos generados</p>',
    en: '<p>You currently have no completed projects</p>',
    pt: '<p>Atualmente, não tem projetos criados</p>',
    fr: '<p>Actualmente no tienes proyectos generados</p>',
    eus: '<p>Actualmente no tienes proyectos generados</p>',
    cat: '<p>Actualmente no tienes proyectos generados</p>',
  },

  home_Data11: {
    es: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
    en: '<p>Generate a new road project and analyze all of the signs.</p>',
    pt: '<p>Crie um novo projeto de estrada e analise todos os seus sinais.</p>',
    fr: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
    eus: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
    cat: '<p>Genera un nuevo proyecto de carretera y analiza todas sus señales.</p>',
  },

};
