export const NAVBAR_I18N_ENTRIES = {
  navbar_Data1: {
    es: 'Nueva Inspección',
    en: '',
    pt: '',
    fr: '',
    eu: '',
    cat: '',
  },
  navbar_Data2: {
    es: 'Galería',
    en: 'Gallery',
    pt: 'Galeria',
    fr: 'Galería',
    eu: 'Galería',
    cat: 'Galería',
  },
  navbar_Data3: {
    es: 'Cuenta',
    en: 'Account',
    pt: 'Conta',
    fr: 'Cuenta',
    eu: 'Cuenta',
    cat: 'Cuenta',
  },
  navbar_Data4: {
    es: 'Chat online',
    en: 'Online chat',
    pt: 'Chat online',
    fr: 'Chat online',
    eu: 'Chat online',
    cat: 'Chat online',
  },

};
