import {Injectable} from '@angular/core';
import {RestClientService} from 'src/app/core/services/api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Inspection} from '../../../../models/inspection';

/**
 * Servicio para las inspecciones
 */
@Injectable({
    providedIn: 'root'
})
export class Ec2RemoteService {

  /**
   * Devuelve la url de la API
   */
  private URL = '/ec2';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Devuelve las instancias de EC2
   * @param inspection
   * @returns
   */
  public startProcessingServer(inspection: Inspection): Observable<any> {
    return this.restClientService.post(`${this.URL}/start-processing-server/${inspection.id}`, null);
  }
}
