<div class="information-container">
  <h1>{{currentOption.title}}</h1>
  <div class="information-content-container" [ngClass]="{'research-menu': research}">
    <div class="information-menu">
      <div class="information-menu-item" *ngFor="let option of options; let last = last">
        <div class="information-menu-item-content" routerLink="{{option.url}}">
          <img class="d-md-block d-lg-none white-img" src="{{option.imageWhite}}">
          <img class="d-none d-lg-block" src="{{option.imageBlue}}">
          <p [ngClass]="{'active': option.url === currentOption.url}">{{option.title}}</p>
        </div>
        <div *ngIf="!last" class="line"></div>
      </div>
    </div>
    <!-- <div class="information-content" *ngIf="!research" [innerHTML]="text"> -->
    <div class="information-content" *ngIf="!research">
      <app-information-content [informationType]="informationType"></app-information-content>
    </div>
    <div class="information-content-project" *ngIf="research">
      <img class="imasd-img" src="/assets/images/information/imasd-information.png" alt=""></div>
  </div>
</div>
