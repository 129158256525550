import {Injectable} from '@angular/core';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Payment} from '../../../../shared/models/model/payment';
import {PaymentFilter} from '../../../../shared/models/model/payment-filter';
import {PaymentResponse} from '../../../../shared/models/model/payment-response';

/**
 * Servicio para los pagos
 */
@Injectable({
  providedIn: 'root'
})
export class PaymentRemoteService {

  /**
   * Devuelve la url de la API
   */
  private URL = 'payments';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Añade la petición de pago
   * @param payment
   * @returns
   */
  public add(payment: Payment): Observable<Payment> {
    return this.restClientService.post(`${this.URL}`, payment);
  }

  /**
   * Devuelve los pagos del usuario
   * @param filter
   * @returns
   */
  public all(filter: PaymentFilter): Observable<PaymentResponse[]> {
    return this.restClientService.get(`${this.URL}`, filter);
  }

  /**
   * Genera el comprobante de pago
   * @param paymentId
   * @returns
   */
  public generateInvoice(paymentId: number) {
    return this.restClientService.get(`${this.URL}/pdf/${paymentId}`, );
  }
}
