import {PreloadingStrategy, Route} from '@angular/router';
import {of} from 'rxjs';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

/**
 * Componente para pre-cargar los modulos
 */
@Injectable()
export class CustomPreloadingStrategy implements PreloadingStrategy {
  /**
   * Pre-carga los modulos
   */
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data.preload) {
      return load();
    } else {
      return of(null);
    }
  }
}
