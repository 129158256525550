import {Injectable} from '@angular/core';
import {Retrievable} from '../../../../shared/models/retrievable';
import {Searchable} from '../../../../shared/models/searchable';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Sector} from '../../../../shared/models/company/sector';
import {SectorSearchCriteria} from '../../../../shared/models/company/sector-search-criteria';

/**
 * Servicio para los sectores
 */
@Injectable({
  providedIn: 'root'
})
export class SectorRemoteService implements
Searchable<Sector, SectorSearchCriteria>,
Retrievable<Sector, number> {

  /**
   * Devuelve la url de la API
   */
  private URL = '/sectors';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Devuelve listado de sectores
   * @param criteria
   * @returns
   */
  public search(criteria?: SectorSearchCriteria): Observable<Sector[]> {
    return this.restClientService.get(this.URL, criteria);
  }

  /**
   * Devuelve un sector
   * @param id
   * @returns
   */
  public get(id: number): Observable<Sector> {
    return this.restClientService.get(`${this.URL}/${id}`);
  }
}
