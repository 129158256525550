import {Injectable} from '@angular/core';
import {RestClientService} from 'src/app/core/services/api-access/rest-client.service';
import {Observable} from 'rxjs';
/**
 * Servicio para los archivos de proyecto
 */
@Injectable({
    providedIn: 'root'
})
export class ProjectFilesRemoteService {

  /**
   * Devuelve la url de la API
   */
  private URL = '/project-files';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Devuelve la url de la imagen de previsualización del proyecto
   * @param projectId
   * @returns
   */
  public getUrlProjectPreviewImage(projectId: number): Observable<string> {
    return this.restClientService.get(`${this.URL}/${projectId}/preview`, null);
  }
  /**
   * Obtiene los frames del video seleccionado
   * @param videoIds
   * @returns
   */
  public getFramesOfVideosSelected(videoIds: number[]): Observable<Blob> {
    return this.restClientService.getBlob(`${this.URL}/get-frames`, {ids: videoIds});
  }
}
