import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-user-by-email-dialog',
  templateUrl: './verify-user-by-email-dialog.component.html',
  styleUrls: ['./verify-user-by-email-dialog.component.scss']
})
export class VerifyUserByEmailDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
