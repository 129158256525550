import {StorageService} from './storage.service';

/**
 * Servicio de almacenamiento base.
 */
export abstract class BaseStorageService<T> {
    /**
     * Constructor.
     * @ignore
     */
    protected constructor(protected storageService: StorageService) {}

    /**
     * Función para guardar el objeto en el almacenamiento.
     * @param value
     */
    public saveObject(value: T) {
        this.storageService.setObject(this.getStorageKey(), value);
    }

    /**
     * Función para obtener el valor del objeto almacenado.
     * @returns
     */
    public getObjectValue(): T {
        return this.storageService.getObject(this.getStorageKey());
    }

    /**
     * Borra del almacenamiento el objeto.
     */
    public deleteFromStorage(): void {
        this.storageService.delete(this.getStorageKey());
    }

    /**
     * Comprueba si el valor de la sesión está almacenado.
     * @returns
     */
    public isSessionValueDefined(): boolean {
        const aux = this.getObjectValue();
        return  aux !== null && aux !== undefined;
    }

    /**
     * Función para obtener la clave de almacenamiento.
     */
    protected abstract getStorageKey(): string;
}
