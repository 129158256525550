import {Component, Input} from '@angular/core';

/** Componente de Google Adsense */
@Component({
  selector: 'app-google-adsense',
  templateUrl: './google-adsense.component.html',
  styleUrls: ['./google-adsense.component.scss']
})
export class GoogleAdsenseComponent {

  /** Establece los créditos iniciales */
  credits = null;

  /** Ancho */
  @Input() width;
  /** Alto */
  @Input() height;
}
