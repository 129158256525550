import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AcceptedLogin} from '../../models/security/accepted-login';
import {Credential} from '../../models/security/credential';
import {RestClientService} from '../../core/services/api-access/rest-client.service';
import {BaseProfile} from '../../models/security/base-profile';
import {SocialAuthService} from 'angularx-social-login';
import {SocialCredential} from '../../models/security/socialcredential';

/**
 * Servicio de acceso a la API de autenticación.
 */
@Injectable({ providedIn: 'root' })
export class LoginRemoteService {
  /**
   * Constructor.
   * @ignore
   */
  constructor(private restClientService: RestClientService, private authService: SocialAuthService) { }

  /**
   * Login.
   * @param credential
   * @returns
   */
  public login<T extends BaseProfile>(credential: Credential): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('/login', credential);
  }

  /**
   * Login con credenciales de red social.
   * @param credential
   * @returns
   */
  public socialLogin<T extends BaseProfile>(credential: SocialCredential): Observable<AcceptedLogin<T>> {
    return this.restClientService.post('/sociallogin', credential);
  }

  /**
   * Logout.
   * @returns
   */
  public logout(): Observable<any> {
    this.socialSignOut();
    return this.restClientService.get('/logout', null);
  }

  /**
   * Cierra la sesión de la red social.
   */
  private socialSignOut() {
    this.authService.signOut();
  }

  /**
   * Login con token.
   * @param token
   * @returns
   */
  public loginWithToken<T extends BaseProfile>(token: string): Observable<AcceptedLogin<T>> {
    return this.restClientService.get(`/user-by-token/${token}`);
  }
}
