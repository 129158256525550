<div class="slider-item" *ngIf="inspection">
  <img *ngIf="!favIconActive" class="fav-icon" src="../../../../../assets/images/gallery/fav-icon.svg" (click)="addToFav()">
  <img *ngIf="favIconActive" class="fav-icon active-icon" src="../../../../../assets/images/gallery/fav-icon-active.svg" (click)="addToFav()">
  <div class="wrapper" (click)="click(inspection.id)">
    <div class="opacity-div"></div>
    <img [src]="inspection.preview" />
    <p class="model-name">{{inspection.name | split:10 }}</p>
    <div class="line"></div>
    <div class="gallery-item-user-info-container d-none d-md-flex">
      <div class="gallery-item-user-info-subcontainer">
        <img [src]="inspection.user.avatar" class="user-photo" (error)="setDefaultAvatar($event)" />
        <div class="gallery-item-user-info-text">
          <p class="gallery-item-user-info-name">{{ inspection.user.name }} {{ inspection.user.surname }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
