import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CookieService } from 'src/app/services/cookies/cookie.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';

@Component({
  selector: 'app-cookies-dialog',
  templateUrl: './cookies-dialog.component.html',
  styleUrls: ['./cookies-dialog.component.scss']
})
export class CookiesDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CookiesDialogComponent>,
    private authenticationService: AuthenticationService,
    private cookieService: CookieService,
    private router: Router,) {}

  userId: number;
  cookieAccepted: any;

  ngOnInit(): void {
    this.authenticationService.get().subscribe(user => {
      this.userId = user.id;
    });
  }

  /**
   * Cierra el dialogo
   */
  acceptCookies(): void {
    this.cookieService.acceptCookies();
    this.dialogRef.close();
  }

  noAcceptCookies(): void {
    this.dialogRef.close();
  }

  goToCookiesPolicy(): void {
    this.router.navigateByUrl('/cookie-policy');
  }

}
