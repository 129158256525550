export const LENGUAGE_I18N_ENTRIES = {
  lenguage_Data1: {
    es: '<p>Idioma</p>',
    en: '<p>Language</p>',
    pt: '<p>Idioma</p>',
    fr: '<p>Langue</p>',
    eus: '<p>Hizkuntza</p>',
    cat: '<p>Idioma</p>',
  },

  lenguage_Data2: {
    es: '<p>Español</p>',
    en: '<p>Spanish</p>',
    pt: '<p>Espanhol</p>',
    fr: '<p>Espagnol</p>',
    eus: '<p>Espainiako</p>',
    cat: '<p>Espanyol</p>',
  },

  lenguage_Data3: {
    es: '<p>Inglés</p>',
    en: '<p>English</p>',
    pt: '<p>Inglês</p>',
    fr: '<p>Anglais</p>',
    eus: '<p>Ingelera</p>',
    cat: '<p>Anglès</p>',
  },

  lenguage_Data4: {
    es: '<p>Portugués</p>',
    en: '<p>Portuguese</p>',
    pt: '<p>Português</p>',
    fr: '<p>Portugais</p>',
    eus: '<p>Portugesa</p>',
    cat: '<p>Portuguès</p>',
  },

  lenguage_Data5: {
    es: '<p>Francés</p>',
    en: '<p>French</p>',
    pt: '<p>Francês</p>',
    fr: '<p>Français</p>',
    eus: '<p>Frantsesa</p>',
    cat: '<p>Francès</p>',
  },

  lenguage_Data6: {
    es: '<p>Euskera</p>',
    en: '<p>Basque</p>',
    pt: '<p>Basco</p>',
    fr: '<p>Basque</p>',
    eus: '<p>Euskara</p>',
    cat: '<p>Basc</p>',
  },

  lenguage_Data7: {
    es: '<p>Catalán</p>',
    en: '<p>Catalan</p>',
    pt: '<p>Catalão</p>',
    fr: '<p>Catalan</p>',
    eus: '<p>Katalana</p>',
    cat: '<p>Català</p>',
  },

};
