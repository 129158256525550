import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

/**
 * Componente para manejar los errores de la API
 */
@Injectable({
    providedIn: 'root',
})
export class RestClientErrorHandlerService {

  /**
   * Constructor
   */
    constructor() {}

    /**
     * Función que maneja los errores de la petición
     * @param httpErrorResponse
     * @returns
     */
    public handleError(httpErrorResponse: HttpErrorResponse) {
      return throwError(httpErrorResponse.error);
    }

    /**
     * Devuelve el error
     * @param httpErrorResponse
     * @returns
     */
    public handleBlobError(httpErrorResponse: HttpErrorResponse) {
        return this.parseErrorBlob(httpErrorResponse).pipe(catchError((err) => this.handleError(err)));
    }

    /**
     * Parse al error
     * @param err
     * @returns
     */
    private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
        const obs = new Observable((observer: any) => {
            reader.onloadend = () => {
              const errResponse = Object.assign([], err);
              observer.error(errResponse);
              observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }
}
