import {Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';
import {I18nService} from '../../core/services/i18n.service';

/**
 * Directiva de traducción
 */
@Directive({
    selector: '[appI18n]',
})
export class I18nDirective implements OnChanges {
    /**
     * Clave de traducción innertext.
     */
    @HostBinding('innerText')
    public innerText: string;

  // tslint:disable-next-line:no-input-rename
    /**
     * Clave de traducción appI18n.
     */
    @Input('app-i18n')
    private key: string;

  // tslint:disable-next-line:no-input-rename
    /**
     * Atributo del elemento donde se va a escribir la traducción.
     */
    @Input('app-i18n-attr')
    private attribute: string;

    /**
     * Constructor.
     * @ignore
     */
    constructor(private i18nService: I18nService, private element: ElementRef) { }

    /**
     * Cambios en los parámetros de entrada.
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        const text = this.i18nService.getText(this.key);
        if (this.attribute) {
            this.element.nativeElement[this.attribute] = text;
        } else {
            this.innerText = text;
        }
    }
}
