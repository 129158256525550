export const LOGIN_I18N_ENTRIES = {
  login_Data1: {
    es: '<p>Iniciar sesión</p>',
    en: '<p>Login</p>',
    pt: '<p>Entrar</p>',
    fr: '<p>Connexion</p>',
    eus: '<p>Saioa hasi</p>',
    cat: '<p>Iniciar sessió</p>',
  },

  login_Data2: {
    es: '<p>Correo electrónico</p>',
    en: '<p>Email</p>',
    pt: '<p>Email</p>',
    fr: '<p>Email</p>',
    eus: '<p>Email</p>',
    cat: '<p>Correu electrònic</p>',
  },

  login_Data3: {
    es: '<p>Contraseña</p>',
    en: '<p>Password</p>',
    pt: '<p>Senha</p>',
    fr: '<p>Mot de passe</p>',
    eus: '<p>Pasahitza</p>',
    cat: '<p>Contrasenya</p>',
  },

  login_Data4: {
    es: '<p>Recuérdame</p>',
    en: '<p>Remember me</p>',
    pt: '<p>Lembrar-me</p>',
    fr: '<p>Souviens-toi de moi</p>',
    eus: '<p>Gogoratu</p>',
    cat: '<p>Recorda\'m</p>',
  },

  login_Data5: {
    es: '<p>Iniciar sesión</p>',
    en: '<p>Login</p>',
    pt: '<p>Entrar</p>',
    fr: '<p>Connexion</p>',
    eus: '<p>Saioa hasi</p>',
    cat: '<p>Iniciar sessió</p>',
  },

  login_Data6: {
    es: '<p>¿Es tu primera vez?</p>',
    en: '<p>Is this your first time?</p>',
    pt: '<p>É a sua primeira vez?</p>',
    fr: '<p>Est-ce votre première fois?</p>',
    eus: '<p>Hau zure lehenengo aldiz?</p>',
    cat: '<p>És la teva primera vegada?</p>',
  },

  login_Data7: {
    es: '<p>Crea una cuenta gratis</p>',
    en: '<p>Create a free account</p>',
    pt: '<p>Crie uma conta gratuita</p>',
    fr: '<p>Créez un compte gratuit</p>',
    eus: '<p>Sortu kontu bat</p>',
    cat: '<p>Crea un compte gratuït</p>',
  },

  login_Data8: {
    es: '<p>¿Has olvidado tu contraseña?</p>',
    en: '<p>Have you forgotten your password?</p>',
    pt: '<p>Você esqueceu sua senha?</p>',
    fr: '<p>Avez-vous oublié votre mot de passe?</p>',
    eus: '<p>Zure pasahitza ahaztu duzu?</p>',
    cat: '<p>Has oblidat la teva contrasenya?</p>',
  },

  login_Data9: {
    es: '<p>Restablece la contraseña</p>',
    en: '<p>Reset password</p>',
    pt: '<p>Redefinir senha</p>',
    fr: '<p>Réinitialiser le mot de passe</p>',
    eus: '<p>Berrezarri pasahitza</p>',
    cat: '<p>Restableix la contrasenya</p>',
  },

  login_Data10: {
    es: '<p>Prevención e</p><p>innovación</p>',
    en: '<p>Prevention and</p><p>innovation</p>',
    pt: '<p>Prevenção e</p><p>inovação</p>',
    fr: '<p>Prévention et</p><p>innovation</p>',
    eus: '<p>Prevención e</p><p>innovación</p>',
    cat: '<p>Prevenció i</p><p>innovació</p>',
  },

  login_Data11: {
    es: '<p>Inteligencia artificial</p><p>para inventarios viales.</p>',
    en: '<p>Artificial intelligence</p><p>for road inventories.</p>',
    pt: '<p>Inteligência artificial</p><p>para inventários rodoviários.</p>',
    fr: '<p>Intelligence artificielle</p><p>pour les inventaires routiers.</p>',
    eus: '<p>Inteligentzia artifiziala</p><p>bideen inbentarioetarako.</p>',
    cat: '<p>Inteligència artificial</p><p>per a inventaris viaris.</p>',
  },

  login_Data12: {
    es: '<p>Términos de uso</p>',
    en: '<p>Terms of use</p>',
    pt: '<p>Termos de uso</p>',
    fr: '<p>Conditions d\'utilisation</p>',
    eus: '<p>Erabilera baldintzak</p>',
    cat: '<p>Termes d\'ús</p>',
  },

  login_Data13: {
    es: '<p>Política de privacidad</p>',
    en: '<p>Privacy policy</p>',
    pt: '<p>Política de privacidade</p>',
    fr: '<p>Politique de confidentialité</p>',
    eus: '<p>Pribatutasun politika</p>',
    cat: '<p>Política de privacitat</p>',
  },

  login_Data14: {
    es: '<p>Políticas de cookies</p>',
    en: '<p>Cookie policy</p>',
    pt: '<p>Política de cookies</p>',
    fr: '<p>Politique des cookies</p>',
    eus: '<p>Cookien politika</p>',
    cat: '<p>Política de cookies</p>',
  },

  login_Data15: {
    es: '<p>R & D Projects</p>',
    en: '<p>R & D Projects</p>',
    pt: '<p>R & D Projects</p>',
    fr: '<p>R & D Projects</p>',
    eus: '<p>R & D Projects</p>',
    cat: '<p>R & D Projects</p>',
  },

  login_Data16: {
    es: '<p>¿Has olvidado tu contraseña?</p>',
    en: '<p>Have you forgotten your password?</p>',
    pt: '<p>Você esqueceu sua senha?</p>',
    fr: '<p>Avez-vous oublié votre mot de passe?</p>',
    eus: '<p>Zure pasahitza ahaztu duzu?</p>',
    cat: '<p>Has oblidat la teva contrasenya?</p>',
  },

  login_Data17: {
    es: '<p>Escribe tu correo y haz click en</p><p>OBTENER CORREO</p>',
    en: '<p>Write your email and click on</p><p>GET EMAIL</p>',
    pt: '<p>Escreva seu e-mail e clique em</p><p>OBTER E-MAIL</p>',
    fr: '<p>Écrivez votre email et cliquez sur</p><p>OBTENIR UN EMAIL</p>',
    eus: '<p>Idatzi zure e-posta eta sakatu</p><p>LORTU E-POSTA</p>',
    cat: '<p>Escriu el teu correu i fes clic a</p><p>OBTENIR CORREU</p>',
  },

  login_Data18: {
    es: '<p>Le enviaremos un email con instrucciones</p><p>para restablecer su contraseña.</p>',
    en: '<p>We will send you an email with instructions</p><p>to reset your password.</p>',
    pt: '<p>Nós lhe enviaremos um e-mail com instruções</p><p>para redefinir sua senha.</p>',
    fr: '<p>Nous vous enverrons un email avec des instructions</p><p>pour réinitialiser votre mot de passe.</p>',
    eus: '<p>E-posta bat bidaliko dizugu zure pasahitza</p><p>berrezartzeko argibideekin.</p>',
    cat: '<p>Li enviarem un correu amb instruccions</p><p>per a restablir la teva contrasenya.</p>',
  },

  login_Data19: {
    es: '<p>Correo electrónico</p>',
    en: '<p>Email</p>',
    pt: '<p>E-mail</p>',
    fr: '<p>Email</p>',
    eus: '<p>E-posta</p>',
    cat: '<p>Correu electrònic</p>',
  },

  login_Data20: {
    es: '<p>OBTENER CORREO</p>',
    en: '<p>GET EMAIL</p>',
    pt: '<p>OBTER E-MAIL</p>',
    fr: '<p>OBTENIR UN EMAIL</p>',
    eus: '<p>LORTU E-POSTA</p>',
    cat: '<p>OBTENIR CORREU</p>',
  },

  login_Data21: {
    es: '<p>¿Tienes una cuenta?</p>',
    en: '<p>Do you have an account?</p>',
    pt: '<p>Você tem uma conta?</p>',
    fr: '<p>Avez-vous un compte?</p>',
    eus: '<p>Zure kontua dago?</p>',
    cat: '<p>Tens un compte?</p>',
  },

  login_Data22: {
    es: '<p>Inicia sesión</p>',
    en: '<p>Sign in</p>',
    pt: '<p>Entrar</p>',
    fr: '<p>Se connecter</p>',
    eus: '<p>Sartu</p>',
    cat: '<p>Inicia sessió</p>',
  },

  login_Data23: {
    es: '<p>Crea una cuenta gratis</p>',
    en: '<p>Create a free account</p>',
    pt: '<p>Crie uma conta gratuita</p>',
    fr: '<p>Créer un compte gratuit</p>',
    eus: '<p>Sortu kontu bat</p>',
    cat: '<p>Crea un compte gratuït</p>',
  },

  login_Data24: {
    es: '<p>Nombre</p>',
    en: '<p>Name</p>',
    pt: '<p>Nome</p>',
    fr: '<p>Nom</p>',
    eus: '<p>Izena</p>',
    cat: '<p>Nom</p>',
  },

  login_Data25: {
    es: '<p>Contraseña</p>',
    en: '<p>Password</p>',
    pt: '<p>Senha</p>',
    fr: '<p>Mot de passe</p>',
    eus: '<p>Pasahitza</p>',
    cat: '<p>Contrasenya</p>',
  },

  login_Data26: {
    es: '<p>Confirma la contraseña</p>',
    en: '<p>Confirm password</p>',
    pt: '<p>Confirme a senha</p>',
    fr: '<p>Confirmer le mot de passe</p>',
    eus: '<p>Berretsi pasahitza</p>',
    cat: '<p>Confirma la contrasenya</p>',
  },

  login_Data27: {
    es: '<p>Acepto</p>',
    en: '<p>I accept</p>',
    pt: '<p>Eu aceito</p>',
    fr: '<p>J\'accepte</p>',
    eus: '<p>Onartzen dut</p>',
    cat: '<p>Accepto</p>',
  },

  login_Data28: {
    es: '<p>Términos de uso</p>',
    en: '<p>Terms of use</p>',
    pt: '<p>Termos de uso</p>',
    fr: '<p>Conditions d\'utilisation</p>',
    eus: '<p>Erabilera baldintzak</p>',
    cat: '<p>Termes d\'ús</p>',
  },

  login_Data29: {
    es: '<p>Comenzar</p>',
    en: '<p>Start</p>',
    pt: '<p>Começar</p>',
    fr: '<p>Commencer</p>',
    eus: '<p>Hasi</p>',
    cat: '<p>Comença</p>',
  },

  login_Data30: {
    es: '<p>Iniciar sesión con Apple</p>',
    en: '<p>Sign in with Apple</p>',
    pt: '<p>Entrar com Apple</p>',
    fr: '<p>Se connecter avec Apple</p>',
    eus: '<p>Sartu Applerekin</p>',
    cat: '<p>Inicia sessió amb Apple</p>',
  },

};
