import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AppConfigService} from './app-config.service';
import {AppConfig} from '../../core/models/config/app-config';

/**
 * Servicio para la carga de la configuración de la aplicación
 */
@Injectable()
export class AppConfigLoaderService {
  /**
   * Configuración de la aplicación
   */
  private appConfig: AppConfig;
  /**
   * Prefijo de la URL
   */
  private uriPrefix = 'assets/config/';
  /**
   * Sufijo de la URL
   */
  private uriSuffix = '.json';

  /**
   * Constructor
   * @ignore
   */
  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) {}

  /**
   * Método para cargar la configuración de la aplicación
   * @returns
   */
  public loadConfiguration(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.httpClient.get<AppConfig>('./assets/config/app-config.json')
        .subscribe((appConfig) => {
          this.appConfigService.setAppConfig(appConfig);
          resolve(true);
        });
    });
  }

  /**
   * Método para obtener la configuración de la aplicación, prefijo + nombre de archivo + sufijo
   * @param filename
   * @returns
   */
  public get(filename: string): Promise<any> {
    return this.httpClient.get<any>(this.uriPrefix + filename + this.uriSuffix).toPromise();
  }

  /**
   * Método para obtener la configuración de la aplicación
   * @returns
   */
    public getAppConfig(): AppConfig {
        return this.appConfig;
    }

    /**
     * Método para obtener la configuración de la aplicación sin caché
     * @param appConfig
     */
    public setAppConfig(appConfig: AppConfig): void {
        this.appConfig = appConfig;
    }
}
