import { Route } from '@angular/router';
import { HomeComponent } from '../components/home/home.component';
import { PermissionGuard } from '../services/security/guards/auth.guard';
import { InformationPageComponent } from '../components/information-page/information-page.component';
import { LayoutComponent } from '../commons/components/layout/layout.component';
/**
 * Componente app routes
 */
export const APP_ROUTES: Route[] = [
  {
    path: '',
    loadChildren: () => import('../auth/auth.module').then((m) => m.AuthModule),
    data: { preload: true },
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [PermissionGuard],
      },
      {
        path: 'new-project',
        loadChildren: () =>
          import('../new-project/new-project.module').then(
            (m) => m.NewProjectModule
          ),
      },
      {
        path: 'project-management',
        loadChildren: () =>
          import('../project-management/project-management.module').then(
            (m) => m.ProjectManagementModule
          ),
      },
      {
        path: 'gallery',
        loadChildren: () =>
          import('../gallery/gallery.module').then((m) => m.GalleryModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('../profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'inspections/:code',
        loadChildren: () =>
          import('../view-project/view-project.module').then(
            (m) => m.ViewProjectModule
          ),
      },
      {
        path: 'inspections-processing',
        loadChildren: () =>
          import('../projects-processing/projects-processing.module').then(
            (m) => m.ProjectsProcessingModule
          ),
      },
      {
        path: 'terms-of-use',
        component: InformationPageComponent,
        // canActivate: [PermissionGuard]
      },
      {
        path: 'privacy-policy',
        component: InformationPageComponent,
        // canActivate: [PermissionGuard]
      },
      {
        path: 'cookie-policy',
        component: InformationPageComponent,
        // canActivate: [PermissionGuard]
      },
      {
        path: 'research-development',
        component: InformationPageComponent,
        canActivate: [PermissionGuard],
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
