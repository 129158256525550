/**
 * Componente event data
 */
export class EventData {
  /**
   * Nombre
   */
  name: string;
  /**
   * Valor
   */
  value: any;
  /**
   * Constructor
   * @ignore
   */
  constructor(name, value) {
    this.name = name;
    this.value = value;
  }
}
