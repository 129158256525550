export const FOOTER_I18N_ENTRIES = {
  footer_Data1: {
    es: '<p>Inicio</p>',
    en: '<p>Home</p>',
    pt: '<p>Início</p>',
    fr: '<p>Inicio</p>',
    eus: '<p>Inicio</p>',
    cat: '<p>Inicio</p>',
  },

  footer_Data2: {
    es: '<p>Nuevo Proyecto</p>',
    en: '<p>New project</p>',
    pt: '<p>Novo projeto</p>',
    fr: '<p>Nuevo Proyecto</p>',
    eus: '<p>Nuevo Proyecto</p>',
    cat: '<p>Nuevo Proyecto</p>',
  },

  footer_Data3: {
    es: '<p>Galería</p>',
    en: '<p>Gallery</p>',
    pt: '<p>Galeria</p>',
    fr: '<p>Galería</p>',
    eus: '<p>Galería</p>',
    cat: '<p>Galería</p>',
  },

  footer_Data4: {
    es: '<p>Perfil</p>',
    en: '<p>Profile</p>',
    pt: '<p>Perfil</p>',
    fr: '<p>Perfil</p>',
    eus: '<p>Perfil</p>',
    cat: '<p>Perfil</p>',
  },

  footer_Data5: {
    es: '<p>Descarga la aplicación:</p>',
    en: '<p>Download app:</p>',
    pt: '<p>Transfira a aplicação:</p>',
    fr: '<p>Descarga la aplicacion:</p>',
    eus: '<p>Descarga la aplicacion:</p>',
    cat: '<p>Descarga la aplicacion:</p>',
  },

  footer_Data6: {
    es: '<p>Siguenos en:</p>',
    en: '<p>Follow us on:</p>',
    pt: '<p>Siga-nos no:</p>',
    fr: '<p>Siguenos en:</p>',
    eus: '<p>Siguenos en:</p>',
    cat: '<p>Siguenos en:</p>',
  },

  footer_Data7: {
    es: '<p>Producto desarrollado por ecaptureDtech</p>',
    en: '<p></p>',
    pt: '<p></p>',
    fr: '<p></p>',
    eus: '<p></p>',
    cat: '<p></p>',
  },

  footer_Data8: {
    es: '<p>Terminos de uso</p>',
    en: '<p>Terms of use</p>',
    pt: '<p>Termos de utilização</p>',
    fr: '<p>Terminos de uso</p>',
    eus: '<p>Terminos de uso</p>',
    cat: '<p>Terminos de uso</p>',
  },

  footer_Data9: {
    es: '<p>Politica de privacidad</p>',
    en: '<p>Privacy Policy</p>',
    pt: '<p>Política de Privacidade</p>',
    fr: '<p>Politica de privacidad</p>',
    eus: '<p>Politica de privacidad</p>',
    cat: '<p>Politica de privacidad</p>',
  },

  footer_Data10: {
    es: '<p>Politicas de cookies</p>',
    en: '<p>Cookie policies</p>',
    pt: '<p>Política de Cookies</p>',
    fr: '<p>Politica de cookies</p>',
    eus: '<p>Politica de cookies</p>',
    cat: '<p>Politica de cookies</p>',
  },

};
