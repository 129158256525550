import {Injectable} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';
import {EventData} from '../models/event-data';

/**
 * Servicio de eventos.
 */
@Injectable({
  providedIn: 'root'
})
export class EventService {
  /** Obtiene Subbject */
  private subject$ = new Subject();

  /**
   * Constructor.
   * @ignore
   */
  constructor() { }

  /**
   * Emite un evento.
   * @param event
   */
  emit(event: EventData) {
    this.subject$.next(event);
  }

  /**
   * Obtiene un evento.
   * @param eventName
   * @param data
   * @returns
   */
  on(eventName: string, data: any): Subscription {
    return this.subject$.pipe(
      filter( (e: EventData) => e.name === eventName),
      map( (e: EventData) => e.value)).subscribe(data);
  }
}
