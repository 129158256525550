import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from './services/cookies/cookie.service';
import { MatDialog } from '@angular/material/dialog';
import { CookiesDialogComponent } from './components/dialogs/cookies-dialog/cookies-dialog.component';
import { AuthenticationService } from './services/security/authentication.service';
import { AuthUserService } from './services/security/auth-user.service';
import { VerifyUserByEmailDialogComponent } from './commons/components/dialogs/verify-user-by-email-dialog/verify-user-by-email-dialog.component';
/** variable tag */
declare var gtag;
/** Componente app */
@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  /**
   * Constructor
   * @param router
   */
  constructor(
    private router: Router,
    private cookieService: CookieService,
    private dialog: MatDialog,
    private authUserService: AuthUserService,
    private authenticationService: AuthenticationService
  ) {}

  /**
   * Inicializa el componente
   */
  ngOnInit() {
    this.authenticationService.get(false).subscribe((user) => {
      this.authUserService.setUser(user);
      if (user.email_verified !== null) {
        this.router.navigateByUrl('/login');
        this.dialog.open(VerifyUserByEmailDialogComponent, {
          autoFocus: false,
          disableClose: true,
          hasBackdrop: true,
          panelClass: 'two-Factor-Dialog-Component',
        });
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-7QMBW7SB6T', {
          page_path: event.urlAfterRedirects,
        });
      }
    });

    if (!this.cookieService.isCookieAccepted()) {
      this.dialog.open(CookiesDialogComponent, {
        panelClass: 'cookies-dialog',
        width: '55%',
        height: '16%',
        position: {
          right: '30px',
          bottom: '30px',
        },
        autoFocus: false,
        hasBackdrop: false,
        disableClose: false,
      });
    }
  }
}
