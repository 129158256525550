<div *ngIf="informationType === '/terms-of-use'">
	<style type="text/css">
		<!--
			@page { margin-left: 1.18in; margin-right: 1.18in; margin-top: 0.98in; margin-bottom: 0.98in }
			P { margin-bottom: 0.08in; direction: ltr; widows: 2; orphans: 2 }
			P.western { font-family: "Times New Roman", serif }
			P.cjk { font-family: "Times New Roman"; so-language: en-US }
			P.ctl { font-family: "Times New Roman" }
			A:link { color: #0000ff; so-language: zxx }
		-->
		</style>


	<p class="western" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="4" style="font-size: 16pt"><b>CONDICIONES
	GENERALES DE USO DE LA PLATAFORMA WEB Y/O CONTENIDOS</b></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>1.INFORMACIÓN
	GENERAL </b></font></font></font>
	</p>
	<p class="western" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Los
	términos y condiciones que se indican a continuación (en adelante,
	“Condiciones Generales”), regulan el acceso, registro,
	navegación, descarga y el uso de/a todas y cada una de las páginas
	de la App y plataforma web y localizables bajo el dominio
	</font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">https://eyesnroad.com
	</font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">y
	sus respectivos subdominios y subdirectorios (en adelante,
	"App/Plataforma Web" o “Sitios Web”) siendo estos
	dominios propiedad de eCapture Research and Development SL. Estas
	Condiciones Generales regulan asimismo el acceso, descarga y uso de
	cualquier otra información, texto, gráficos, fotos, imágenes,
	videos, modelos 3d, música, sonidos, aplicaciones informáticas o
	cuentas en cualesquiera plataformas sociales que sean creadas,
	diseñadas, promocionadas o difundidas por eCapture Research and
	Development SL y a las que se pueda acceder desde el Sitio Web o
	desde cualquier otra página web o aplicación informática (en
	adelante, “Contenidos”). </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	cumplimiento de lo dispuesto en el artículo 10 de la Ley 34/2002, de
	11 de julio, de servicios de la sociedad de la información y de
	comercio electrónico, eCapture Research and Development SL. (en
	adelante, “</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">)
	pone a disposición de sus usuarios la siguiente información
	registral del titular del Sitio Web y/o Contenidos:</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><span lang="en-US">Denominación
	social: eCapture Research and Development SL </span></font></font>
	</p>
	<p lang="en-GB" class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Número
	de identificación Fiscal:</font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><u>
	</u></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">ESB06619944</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Datos
	Mercantiles: Inscrita en el Registro Mercantil de Badajoz, en el tomo
	539, Folio 171, Hoja BA-23900</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Domicilio
	social: Torre Siglo XXI. Paseo Fluvial N15, P11. 06011 Badajoz,
	España</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Número
	de teléfono: +34 924 10 32 27</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Contacto:</font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><u>
	</u></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">pueden
	ponerse en contacto con </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	mediante correo ordinario a la dirección del domicilio social o a la
	del departamento de asesoría jurídica de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">:
	calle Luis Álvarez Lencero nº 3 Planta 15 Oficina 16, 06011Badajoz;
	o bien a través de la siguiente dirección de correo electrónico:
	customercare@Ey.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">POR
	EL HECHO DE ACCEDER, VER O UTILIZAR LOS MATERIALES O SERVICIOS
	ACCESIBLES EN O A TRAVÉS DEL “SITIO WEB” O A LOS “CONTENIDOS”
	ACCESIBLES EN O A TRAVÉS DEL “SITIO WEB” U OTRAS PÁGINAS WEB O
	APLICACIONES INFORMÁTICAS, EL USUARIO PONE DE MANIFIESTO QUE
	COMPRENDE Y ACEPTA ESTAS “CONDICIONES GENERALES” COMO EQUIVALENTE
	LEGAL DE UN DOCUMENTO FIRMADO POR ESCRITO Y VINCULANTE.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>2.CONDICIONES
	GENERALES DE USO DE LOS “SITIOS WEB” Y DE LOS “CONTENIDOS” </b></font></font></font>
	</p>
	<p class="western" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	acceso y el uso del Sitios Web de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	así como el acceso y uso de los Contenidos quedarán sujetos a las
	presentes Condiciones Generales. La utilización de cualquier página
	o plataforma web titularidad de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o de los Contenidos atribuye la condición de usuario y supone la
	aceptación plena y sin reservas por el usuario de todas las
	Condiciones Generales vigentes en cada momento en que el usuario
	acceda al mismo. </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a modificar en cualquier momento las presentes
	Condiciones Generales. Le sugerimos que revise estas Condiciones
	Generales frecuentemente para estar al corriente de su alcance y de
	cualquier modificación que se haya llevado a cabo. Al acceder a los
	Sitios Web y/o a los Contenidos titularidad de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	con posterioridad a la publicación del aviso de dichas
	modificaciones, alteraciones o actualizaciones, estará aceptando
	cumplir con los nuevos términos. El usuario es consciente de que el
	acceso y utilización del Sitio Web y/o los Contenidos se realiza
	bajo su única y exclusiva responsabilidad. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Algunos
	servicios de los Sitios Web de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y/o algunos Contenidos pueden estar sometidos a condiciones
	particulares, reglamentos e instrucciones que, en su caso,
	sustituyen, completan y/o modifican los presentes términos y
	condiciones (en adelante, “Condiciones Particulares”), y que
	deberán ser aceptados por el usuario antes de realizarse la
	prestación del servicio correspondiente. La prestación del servicio
	a solicitud del usuario, implica la aceptación expresa de las
	Condiciones Particulares que fueran de aplicación. Dentro de la
	expresión "Sitio Web" se comprenden, con carácter
	enunciativo pero no limitativo todos los contenidos, los datos,
	gráficos, textos, logos, marcas, software, imágenes, videos,
	modelos 3d, animaciones, creaciones musicales, vídeos, sonidos,
	dibujos, fotografías, expresiones e informaciones y otros incluidos
	en el mismo, y, en general, todas las creaciones expresadas por
	cualquier medio o soporte, tangible o intangible con independencia de
	que sean susceptibles o no de propiedad intelectual de acuerdo al
	Texto Refundido de la Ley de Propiedad Intelectual española.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario utilizará los servicios y materiales disponibles en el Sitio
	Web así como los Contenidos exclusivamente para fines particulares,
	con exclusión de cualquier modalidad de utilización distinta a
	esta, y con exclusión específica a cualquier utilización con ánimo
	de lucro o que reporte beneficio, directo o indirecto al usuario o a
	terceros.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	presentes Condiciones Generales, así como las Condiciones
	Particulares que puedan aplicarse previo aviso en casos concretos,
	son aceptadas expresamente y sin reservas por el usuario por el mero
	hecho de acceder al Sitio Web, utilizar de cualquier forma los
	materiales y servicios del Sitio Web y/o acceder o utilizar de
	cualquier forma los Contenidos y/o participación en promociones o
	concursos. Cuando el acceso y la utilización de ciertos materiales
	y/o servicios del Sitio Web y/o de los Contenidos se encuentre
	sometido a Condiciones Particulares, estas Condiciones Particulares
	serán previamente puestos en conocimiento del usuario, y según los
	casos, sustituirán, completarán y/o modificarán las Condiciones
	Generales aquí recogidas. El acceso y la utilización de dichos
	materiales o servicios sujetos a Condiciones Particulares implicará,
	por consiguiente, la plena adhesión a las Condiciones Particulares
	que los regula en la versión publicada en el momento que el usuario
	acceda a los mismos, quedando dichas Condiciones Particulares
	incorporadas automáticamente a las presentes Condiciones Generales.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	caso de contradicción entre los términos y condiciones manifestados
	en estas Condiciones Generales y las Condiciones Particulares,
	prevalecerán siempre y en todo caso, los términos acordados en las
	Condiciones Particulares aunque únicamente en lo que respecta a las
	disposiciones incompatibles y tan sólo respecto de aquellos
	materiales o servicios del Sitio Web y/o de los Contenidos sometidos
	a dicha específica regulación.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	podrá modificar de forma unilateral y sin aviso previo, la
	prestación, configuración, contenido y servicios del Sitio Web y de
	los Contenidos, así como sus Condiciones Generales. Si estas
	Condiciones Generales fueran sustituidas por otras en todo o en
	parte, dichas nuevas condiciones generales o en su caso las
	particulares, se entenderán aceptadas de forma idéntica a la
	expuesta. No obstante, el usuario del Sitio Web y/o de los Contenidos
	deberá acceder a estas Condiciones Generales y a las Condiciones
	Particulares de los servicios del Sitio Web y/o de los Contenidos que
	utilice, de forma periódica para tener conocimiento de la
	actualización de las mismas, si la hubiera.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	caso de que el usuario no acepte estas Condiciones Generales o, las
	Condiciones Particulares, el usuario deberá abstenerse de acceder al
	Sitio Web y/o a los Contenidos o, en caso de haber accedido,
	abandonarlos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario deberá establecer las medidas de seguridad de carácter
	técnico adecuadas para evitar acciones no deseadas en su sistema de
	información, archivos y equipos informáticos empleados para acceder
	a Internet y, en especial, al Sitio Web y/o los Contenidos, siendo
	consciente de que Internet no es un medio totalmente seguro. El coste
	del acceso telefónico u otro tipo de gasto necesarios para acceder
	al Sitio Web y/o los Contenidos correrá exclusivamente a cargo del
	usuario.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Con
	carácter general, los servicios y materiales ofrecidos a través del
	Sitio Web y/o los Contenidos estarán disponibles en español,
	pudiendo </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	discrecionalmente presentar dichos servicios y materiales
	adicionalmente en otros idiomas.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>3.MENORES
	DE EDAD</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Queda
	prohibido el acceso, registro, navegación, utilización, alojamiento
	y/o descarga de materiales y/o uso de los servicios de cualquier
	Sitio Web y/o Contenidos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	por parte de menores de edad (menores de 16 años). El usuario,
	mediante el registro, manifiesta responsablemente y garantiza que
	tiene al menos 16 años.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">No
	obstante, </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	no puede verificar la certeza de los datos proporcionados, ni se
	responsabilizará de aquellas personas menores de edad que accedan al
	Sitio Web y/o los Contenidos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	incumpliendo dicha condición de acceso. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	recomienda a padres, representantes o tutores legales supervisar o
	tomar las precauciones oportunas durante la navegación de los
	menores por Internet, así como establecer filtros sobre la
	información y los contenidos a los que los menores pueden o no
	acceder.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>4.RESPONSABILIDAD
	DE LOS USUARIOS POR USO DEL SITIO WEB Y/O LOS CONTENIDOS.</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario no podrá en ningún caso modificar o suprimir los datos
	identificativos que existan de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	El usuario únicamente podrá acceder a los servicios y materiales
	del Sitio Web y/o los Contenidos mediante los medios o procedimientos
	que se hayan puesto a su disposición a este efecto en el propio
	Sitio Web y/o en los Contenidos, o que se utilicen habitualmente en
	Internet para ese fin, siempre que no impliquen violación de
	derechos de Propiedad Intelectual/Industrial, o algún tipo de daño
	para el Sitio Web y/o los Contenidos, o a su información o a los
	servicios ofrecidos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario se obliga a utilizar los servicios, la información y los
	materiales del Sitio Web y/o los Contenidos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	de conformidad con la Ley y con estas Condiciones Generales. En
	ningún caso la utilización del Sitio Web y/o los Contenidos por el
	usuario podrá atentar contra la legislación vigente, la moral, las
	buenas costumbres y el orden público, y deberá en todo momento
	hacer un uso correcto y lícito de los servicios, información y
	materiales del Sitio Web y/o los Contenidos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario podrá: </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">O
	</font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Acceder
	y navegar de forma gratuita, y sin necesidad de autorización previa,
	a los materiales y servicios del Sitio Web y/o los Contenidos, sin
	perjuicio de la exigencia de previo registro y/o aceptación de
	Condiciones Particulares respecto de determinados servicios y
	contenidos específicos, según se determine en estas Condiciones
	Generales o, en su caso, en las Condiciones Particulares de dichos
	servicios. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">O
	</font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizar
	los servicios y materiales del Sitio Web y/o los Contenidos para uso
	exclusivamente particular. El usuario en ningún caso podrá realizar
	las siguientes actividades: </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">a)
	Difundir contenidos o propaganda de carácter racista, xenófobo,
	pornográfico, obsceno, denigratorio o que incite o promueva la
	realización de actos delictivos, violentos difamatorios o
	degradantes por razón de edad, sexo, religión o creencias; o que
	haga, promueva o incite directa o indirectamente a la apología del
	terrorismo o que sea contraria a los derechos humanos y los derechos
	fundamentales y libertades de terceros, a la legislación vigente, la
	moral, las buenas costumbres y el orden público, o con fines lesivos
	que puedan perjudicar, dañar o impedir de cualquier forma, el acceso
	a los mismos, en perjuicio de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o  de terceros. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">b)
	Realizar actos contrarios a los derechos de Propiedad Intelectual y/o
	Industrial de sus legítimos titulares</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">c)
	Provocar daños en los sistemas informáticos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	de sus proveedores o de terceros y/o introducir o difundir virus
	informáticos, código o software nocivo u otro tipo de sistemas que
	puedan causar daños o alteraciones en sistemas informáticos, o
	alteraciones no autorizadas de los contenidos, programas o sistemas
	accesibles a través de los materiales o servicios del Sitio Web y/o
	de los Contenidos, o en los sistemas de información, archivos y
	equipos informáticos de los usuarios de los mismos, o el acceso no
	autorizado a cualesquiera materiales y servicios del Sitio Web y/o de
	los Contenidos. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">d)
	Transmitir publicidad a través de cualquier medio y, en especial, a
	través del envío de mensajes electrónicos, cuando el envío de la
	publicidad no haya sido solicitado o autorizado por el receptor. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">e)
	Utilizar el Sitio Web y/o los Contenidos, total o parcialmente, para
	promocionar, vender, contratar, divulgar publicidad o información
	propia o de terceras personas sin autorización previa y por escrito
	de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	o incluir hipervínculos en sus páginas web particulares o
	comerciales al Sitio Web y/o a los Contenidos, salvo autorización
	expresa de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	</font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">f)
	Utilizar los servicios y materiales ofrecidos a través del Sitio Web
	y/o los Contenidos de forma contraria a las Condiciones Generales y/o
	las Condiciones Particulares que regulen el uso de un determinado
	servicio y/o contenido, y en perjuicio o con menoscabo de los
	derechos del resto de usuarios. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">g)
	Eliminar o modificar de cualquier modo los dispositivos de protección
	o identificación de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o sus legítimos titulares que puedan contener el Sitio Web y/o los
	Contenidos, o los símbolos, logos o marcas que </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o los terceros legítimos titulares de los derechos incorporen a sus
	creaciones y que puedan ser objeto de propiedad intelectual o
	industrial. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">h)
	Incluir, sin autorización previa y por escrito de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	en páginas web y/o aplicaciones de responsabilidad o propiedad del
	usuario o de terceros no autorizados "metatags"
	correspondientes a marcas, logos, nombres comerciales o signos
	distintivos propiedad de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	o utilizar marcas, logos, nombres comerciales, o cualquier otro signo
	identificativo que se encuentre sujeto a derechos de propiedad
	intelectual o industrial, sin la previa autorización expresa y por
	escrito de su legítimo titular. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-left: 0.49in; margin-bottom: 0in">
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">i)Incluir
	en un sitio web y/o aplicaciones de su responsabilidad o propiedad un
	hiperenlace que genere una ventana o sesión del software de
	navegación empleado por un usuario de su sitio web, en que se
	incluyan marcas, nombres comerciales o signos distintivos de su
	propiedad y a través del cual se muestre el Sitio Web y/o los
	Contenidos. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	Usuario responderá frente a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	o frente a terceros, de cualesquiera daños y perjuicios de cualquier
	clase que pudieran causarse como consecuencia del incumplimiento o
	inobservancia, directa o indirecta, de las presentes Condiciones
	Generales. </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	velará en todo momento por el respeto al ordenamiento jurídico
	vigente, y se reserva el derecho a denegar discrecionalmente total o
	parcialmente, en cualquier momento y sin necesidad de preaviso, el
	acceso de cualquier usuario al Sitio Web y/o a los Contenidos, cuando
	concurra una o varias circunstancias descritas en la presente
	cláusula.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>5.IDENTIFICADORES
	DE USUARIO Y CONTRASEÑA </b></font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a solicitar el registro de los usuarios para el
	acceso a ciertos servicios o información del Sitio Web y/o de los
	Contenidos, para lo que se solicitará la elección de una contraseña
	que permita la identificación personal del usuario ("nombre"
	y "contraseña"). El identificador del usuario una vez
	creado su perfil, será único para todas las plataformas de
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y las sociedades relacionadas con la misma. Puede obtener más
	información sobre el registro único de los usuarios en la Política
	de Privacidad y Protección de Datos Personales.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	claves de acceso asignadas serán personales e intransferibles, no
	estando permitida la cesión, ni siquiera temporal, a terceros. En
	tal sentido, el usuario se compromete a hacer un uso diligente y a
	mantener en secreto las contraseña/s y nombre/s de usuario/s
	asignado/s, en su caso, para acceder al Sitio Web y/o a los
	Contenidos. En el supuesto de que el usuario conozca o sospeche de la
	pérdida, robo o uso de su contraseña por terceros, deberá poner
	tal circunstancia en conocimiento de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	a la mayor brevedad posible.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario será responsable de los gastos y de los daños y perjuicios
	ocasionados por el acceso y utilización del Sitio Web y/o los
	Contenidos por cualquier tercero que emplee al efecto la/s
	contraseña/s y nombre/s del usuario debido a una utilización no
	diligente o a la pérdida de los mismos por el usuario.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>6.CONDICIONES
	PARTICULARES DE USO DEL SERVICIO</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Procedimiento
	de registro: Si un usuario decide </font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">registrarse
	para la realización de inventarios de señales de trafico, baches y
	líneas (tratamiento </font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">de
	imágenes) y demás servicios que puede ofertar </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	en su Sitio Web, se le solicitará la cumplimentación de formularios
	específicos en cada caso, debiendo rellenar como mínimo aquellos
	campos que se señalen como obligatorios para la consecución de los
	fines y finalidades a los que está destinado ese servicio en
	concreto. Una vez el formulario se ha cumplimentado correctamente, se
	le enviará un correo electrónico de confirmación a la dirección
	indicada por el usuario.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Será
	responsabilidad del usuario que toda la información facilitada
	durante el uso del Sitio Web sea veraz y se encuentre actualizada.
	Los datos de cumplimentación obligatoria se especifican en el propio
	formulario, y su negativa a suministrarlos implicará no poderse dar
	de alta como participante en los mismos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Una
	vez realizado el registro y tras la cumplimentación del formulario
	inicial, se podrá requerir la cumplimentación de información
	adicional garantizándose que para el tratamiento de todos los datos
	personales entregados, </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	cumple con todas las exigencias establecidas en la Ley Orgánica de
	Protección De Datos (LOPD) y legislación complementaria y así
	consta recogido en el apartado de Política de Privacidad y
	Protección de Datos Personales. Las normas y reglas de participación
	en los concursos, sorteos y demás promociones especiales y servicios
	específicos se establecerán en las Bases y Reglas específicas para
	uno de ellos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><a name="_Hlk71282250"></a>
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Procedimiento
	de subida o carga de imágenes o videos al Sitio Web: Todas las
	imágenes, fotos, videos o similar que sean subidas o cargadas por el
	usuario al Sitio Web para la utilización del servicio serán
	</font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">propiedad
	del mismo. Este concede una autorización expresa y automática para
	la realización de inventarios de señales de trafico, baches y
	líneas (tratamiento de imágenes) a través de sus algoritmos, una
	vez acepte el proceso de generación en la plataforma we</font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">b.
	Asimismo, el usuario autoriza expresamente a utilizar los datos o
	metadatos que dichas imágenes, fotografías o videos puedan
	incorporar, y que son usados por la </font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">sociedad
	para el proceso de realización de inventarios de señales de
	trafico, baches y líneas (tratamiento de imágenes).</font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Procedimiento
	de realización de inventarios de señales de trafico, baches y
	líneas (tratamiento de imágenes): La sociedad procederá a realizar
	el servicio de realización de inventarios de señales de trafico,
	baches y líneas (tratamiento de imágenes), una vez el usuario haya
	aceptado su realización en el Sitio Web. El usuario concede a
	</font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	la autorización expresa y automática para la generación del
	proceso en los servidores que la sociedad dispone en su proveedor de
	servicios de computación. Durante este proceso de realización de
	inventarios de señales de trafico, baches y líneas (tratamiento de
	imágenes) no se produce ninguna intervención humana que pueda
	exponer las imágenes, fotos o videos a terceras personas.</font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Procedimiento
	de visualización de los datos obtenidos del proceso de  realización
	de inventarios de señales de trafico, baches y líneas (tratamiento
	de imágenes): El usuario autoriza de manera expresa al
	almacenamiento de los datos generados en los servidores de su
	proveedor de almacenamiento, una vez finalizado el proceso de
	realización de inventarios de señales de trafico, baches y líneas
	(tratamiento de imágenes). </font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario podrá realizar la visualización de los citados datos
	(tratamiento de imágenes) directamente a través del Sitio Web,
	siendo </font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	solo una empresa de almacenamiento de los datos generados en el Sitio
	Web; los cuales son propiedad exclusiva del usuario.</font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000">
	</font><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Proceso
	de eliminación de imágenes, videos, datos, o cualquier información
	subida al Sitio Web: El usuario es la única persona autorizada a
	eliminar los datos, imágenes, fotografías, videos que se hayan
	subido o realizados en el Sitio Web.</font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a revisar todas las imágenes, fotografías,
	videos o datos generados por los usuarios en el Sitio Web con objeto
	de </font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">hacer
	cumplir con las condiciones generales y particulares de uso.
	Asimismo, si </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	detectará que algunas de estas imágenes, fotografías, videos o
	datos generados pudiera incumplir con alguna legislación, o ser
	constitutiva de un delito, procederá a poner a disposición de las
	autoridades judiciales de cada país dichas imágenes, videos o
	fotografías.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>7.
	CONDICIONES DE PAGO Y FACTURACIÓN</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	tarifas cobradas no serán reembolsables. Sin embargo, sí
	procederá la devolución de los importes abonados por los Usuarios
	cuando se produzcan los supuestos previstos en el artículo 21 del
	Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se
	aprueba el texto refundido de la Ley General para la Defensa de los
	Consumidores y Usuarios. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	tarifas, sus actualizaciones, y condiciones de uso estarán
	disponibles en todo momento en la Aplicación y/o Sitio Web y están
	sujetas a posibles modificaciones. Se recomienda al Usuario que
	acceda periódicamente a la Aplicación y/o el Sitio Web para
	conocer las tarifas aplicables en cada momento. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a modificar, ampliar, reducir, establecer y/o
	eliminar cualquier modalidad de pago en cualquier momento según
	estime conveniente para el correcto funcionamiento del Servicio, en
	cuyo caso el Usuario </font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">será
	notificado de tales circunstancias. Las tarifas aplicables al
	Servicio serán cobradas al Usuario al realizar los realización de
	inventarios de señales de trafico, baches y líneas (tratamiento de
	imágenes)  o en la adquisición de algunos de los bonos,  packs o
	subscripciones. Dichas dichas tarifas serán cobradas de forma
	automática a través de los datos de la tarjeta de crédito
	facilitados por el Usuario en el momento del pago, </font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	aquellos supuestos en los que </font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	cobre directamente al cliente a través de la tarjeta de crédito
	facilitada por el Usuario, </font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a realizar una validación del método de
	pago. </font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a restringir el uso y a recuperar el importe de
	las promociones, invitaciones y descuentos a aquellos Usuarios que
	hagan un uso inadecuado, ilícito o abusivo de los mismos, que
	podrán ser informados de tal circunstancia. Se entenderá́ por uso
	inadecuado cualquier utilización de la Aplicación y/o Sitio Web
	por parte del Usuario contraria a Derecho y a las presentes
	Condiciones Generales de Uso. </font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Está
	terminantemente prohibido que un Usuario cree diferentes cuentas de
	</font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o comparta un método de pago electrónico con otros Usuarios con
	el fin de beneficiarse indebidamente de descuentos e invitaciones, lo
	cual se considera un uso abusivo de las mismas. </font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	invitaciones y descuentos perderán su valor a los tres meses de su
	creación. Además, </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva el derecho a anular o reducir el valor de los descuentos,
	invitaciones y promociones en cualquier momento. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	los supuestos en los que los Usuarios paguen con tarjeta de crédito,
	y de acuerdo con lo dispuesto en la Política de Privacidad, los
	datos de la tarjeta de crédito facilitados en en el momento del
	pago serán remitidos a la empresa proveedora de pagos y encargada
	de almacenar dichos datos del Usuario. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	Usuario deberá acceder o podrá ser redirigido al sitio Web del
	proveedor de pagos a través del enlace facilitado al efecto en el
	formulario de pagos. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	todo caso se recomienda al Usuario consultar los términos y
	condiciones de uso y las políticas de privacidad de los sitios Web
	de terceros a los que accedan a través de enlaces incluidos en la
	Aplicación y/o Sitio Web. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	por motivos de seguridad tendrá la potestad de bloquear, cancelar o
	anular cualquier método de pago cuando existan sospechas razonables
	sobre un uso indebido del mismo. Además, no está permitido
	utilizar un mismo método de pago en más de dos cuentas
	independientes. En caso de que eso suceda la empresa puede proceder a
	bloquear el Servicio a todas las cuentas hasta que el Usuario
	demuestre documentalmente la propiedad de ambas cuentas y del método
	de pago introducido. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	los supuestos en los que </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	cobre directamente al Usuario a través de su tarjeta de crédito,
	se remitirá a la dirección de correo electrónica facilitada por
	el Usuario, un resumen del trayecto realizado, a efectos puramente
	informativos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>8.PROPIEDAD
	INTELECTUAL E INDUSTRIAL</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Todos
	los materiales e información del Sitio Web y/o de los Contenidos de
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	están sometidos a la normativa vigente sobre Propiedad Intelectual
	y/o Industrial. Los derechos sobre los materiales y demás elementos
	que se muestran en el Sitio Web y/o en los Contenidos (incluyendo, a
	título meramente ejemplificativo y sin limitación, dibujos, textos,
	gráficos, fotografías, audio, video, software, signos distintivos,
	etc.) pertenecen a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o, en su caso, a terceros que han consentido la cesión de los mismos
	a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	Igualmente pertenecen a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	los logos, nombres comerciales, dominios y marcas de su titularidad.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Los
	materiales e información que pudiere aportar el usuario al Sitio Web
	y/o a los Contenidos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	(fotografía, audio, video, etc.) deberán respetar los derechos de
	imagen y propiedad intelectual (de existir) de los mismos, siendo el
	usuario el único responsable de cualquier reclamación que pudiere
	existir frente a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	como consecuencia de la utilización y difusión de dichos materiales
	e información.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	acceso, navegación, utilización, ubicación y/o descarga de
	materiales y/o uso de servicios del Sitio Web y/o de los Contenidos
	por el usuario, en ningún caso se entenderá como una renuncia,
	transmisión, licencia o cesión total o parcial de los derechos de
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o, en su caso, por parte del titular de los derechos
	correspondientes. El usuario tan sólo dispone de un derecho de uso
	estrictamente personal y privado, exclusivamente con la finalidad de
	disfrutar de las prestaciones del servicio, quedando terminantemente
	prohibida su utilización con ánimo de lucro o para fines
	comerciales.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	consecuencia, no está permitido suprimir, eludir o manipular el
	aviso de derechos de autor ("copyright") y cualesquiera
	otros datos de identificación de los derechos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o de sus respectivos titulares incorporados a los contenidos y/o
	servicios, así como los dispositivos técnicos de protección o
	cualesquiera mecanismos de información y/o de identificación que
	pudieren contenerse en los mismos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	particular, queda terminantemente prohibida la utilización de
	cualesquiera materiales o elementos del Sitio Web y/o de los
	Contenidos para su inclusión, total o parcial, en otros sitios web
	ajenos al Sitio Web y/o los Contenidos sin contar con la autorización
	previa y por escrito de los titulares del Sitio Web. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	referencias a nombres y marcas comerciales o registradas, logotipos u
	otros signos distintivos, ya sean titularidad de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o de terceras empresas, llevan implícitas la prohibición sobre su
	uso sin el consentimiento de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o de sus legítimos propietarios. En ningún momento, salvo
	manifestación expresa, el acceso al o uso del Sitio Web y/o de los
	Contenidos, confiere al usuario derecho alguno sobre las marcas,
	logotipos y/o signos distintivos allí incluidos y protegidos por
	Ley.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Quedan
	reservados todos los derechos de propiedad intelectual e industrial
	y, en particular, queda prohibido modificar, fijar, copiar,
	reutilizar, explotar de cualquier forma, reproducir, transformar,
	doblar, subtitular, ceder, vender, alquilar, prestar, comunicar
	públicamente o permitir el acceso del público a través de
	cualquier modalidad de comunicación pública, hacer segundas o
	posteriores publicaciones, cargar archivos, enviar por correo,
	transmitir, usar, tratar o distribuir de cualquier forma la totalidad
	o parte de los contenidos, elementos, materiales, información y
	productos en su caso, incluidos en el Sitio Web y/o de los Contenidos
	para propósitos públicos o comerciales, así como incluirlos en
	otro sitio web distinto, si no se cuenta con la autorización expresa
	y por escrito de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o, en su caso, del correspondiente titular de los derechos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Si
	la actuación u omisión, culpable o negligente, directa o
	indirectamente imputable al usuario del Sitio Web y/o de los
	Contenidos que origine la infracción de los derechos de propiedad
	intelectual e industrial de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o de terceros, originase a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	daños, pérdidas, obligaciones solidarias, gastos de cualquier
	naturaleza, sanciones, medidas coercitivas, multas y otras cantidades
	surgidas o derivadas de cualquier reclamación, demanda, acción,
	pleito o procedimiento, ya sea civil, penal o administrativo,
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	tendrá derecho a dirigirse contra dicho usuario por todos los medios
	legales a su alcance y reclamar cualesquiera cantidades
	indemnizatorias, daños morales o daños a la propia imagen, daño
	emergente y lucro cesante, costes publicitarios o de cualquier otra
	índole que pudieran resultar para su reparación, importes de
	sanciones o sentencias condenatorias, intereses de demora, el coste
	de la financiación de todas las cantidades en que pudiera resultar
	perjudicada </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	las costas judiciales y el importe de la defensa (incluidos
	procuradores y abogados) en cualquier proceso en el que pudiera
	resultar demandada </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	por las causas anteriormente expuestas, por los daños y perjuicios
	ocasionados por razón de su actuación u omisión, sin perjuicio de
	ejercer cualesquiera otras acciones que en derecho le correspondan a
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	reclamaciones que pudieran interponerse por el usuario en relación
	con posibles incumplimientos de los derechos de propiedad intelectual
	o industrial en relación con el Sitio Web y/o los Contenidos deberán
	dirigirse al Departamento de Asesoría Jurídica de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	sito en </font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Torre
	Siglo XXI C/Paseo Fluvial nº 15 floor 10, Badajoz, Spain, 06004</font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>9.INFORMACIÓN
	Y ELEMENTOS DEL SITIO WEBY/O DE LOS CONTENIDOS</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol type="a">
		<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Calibri, serif"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Información
		Corporativa de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font></p>
	</li></ol>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario conoce y acepta que cualquier dato relativo a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o sus las empresas que integran dicho grupo empresarial, y cuya
	naturaleza sea económica, financiera y/o estratégica (en adelante,
	"Información Corporativa") se lleva a cabo con fines
	meramente informativos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">La
	Información Corporativa se ha obtenido de fuentes fiables, pero,
	pese a haber tomado medidas razonables para asegurarse de que dicha
	información es veraz, real y puede mostrar los resultados
	empresariales de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	este no manifiesta ni garantiza que sea exacta, completa o
	actualizada, y no debe confiarse en la misma en términos absolutos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol type="a" start="2">
		<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Calibri, serif"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Información
		suministrada o publicada por usuarios y/o terceros </font></font></font>
		</p>
	</li></ol>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	Sitio Web y/o los Contenidos pueden incluir información o contenidos
	facilitados por otras fuentes distintas de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	incluyendo información proporcionada por los propios usuarios del
	Sitio Web y/o de los Contenidos. </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	d no garantiza, ni asume responsabilidad alguna sobre la certeza,
	integridad o exactitud de tal información y/o contenidos, incluyendo
	los supuestos expuestos en el apartado 4. relativo a la
	"Responsabilidad de los Usuarios por uso y contenido".</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario no podrá introducir, almacenar o difundir mediante el Sitio
	Web y/o los Contenidos, cualquier contenido o material que infrinja
	derechos de propiedad intelectual o industrial, ni en general ningún
	contenido respecto del cual no ostente, de conformidad con la ley, el
	derecho a reproducirlo, distribuirlo, adaptarlo, copiarlo, fijarlo o
	ponerlo a disposición de terceros. Se entiende por información,
	elementos o contenidos recibidos por el usuario en el Sitio Web y/o a
	través de los Contenidos, aquellos recibidos por cualquier medio, ya
	sean comentarios, sugerencias o ideas, incluso aquellos que contengan
	vídeos, textos, fotografías, imágenes, audio, software, etc.
	Dichos contenidos se considerarán cedidos a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	a título gratuito, por el máximo tiempo permitido y para todo el
	mundo, pudiendo ser utilizados por </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	dentro de los límites establecidos por la normativa de aplicación,
	sin que aplique ninguna obligación en materia de confidencialidad
	sobre dichos contenidos o información. Se entiende incluida en esta
	cesión la autorización al uso de la propia imagen del usuario, sin
	contraprestación, cuando el contenido, en cualquier medio o formato
	contenga esta última, para fines comerciales de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	Está totalmente prohibido el envío por parte de los usuarios de
	información que no pueda ser tratada de esta forma o que contenga
	elementos o contenidos cuya titularidad corresponda a terceros sin
	haber obtenido previamente la autorización para su uso. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Debido
	a la gran cantidad de material que se puede alojar en el Sitio Web
	y/o los Contenidos, resulta imposible por parte de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	verificar la originalidad o no infracción de derechos de terceros
	sobre los contenidos suministrados por el usuario, siendo este el
	último y único responsable a todos los efectos de las infracciones
	que eventualmente podrían cometerse como consecuencia del suministro
	de dicho contenido. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	podrá modificar los materiales suministrados por los usuarios, a fin
	de adaptarlos a las necesidades de formato del Sitio Web y/o de los
	Contenidos.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>10.
	ENLACES O HIPERENLACES AL SITIO WEB Y/O A LOS CONTENIDOS</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario que quiera introducir enlaces o hiperenlaces desde o en sus
	propias páginas Web al Sitio Web y/o Contenidos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	deberá cumplir con las condiciones que se detallan a continuación,
	sin que el desconocimiento de las mismas evite las responsabilidades
	derivadas por su incumplimiento:</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">a)
	El enlace o hiperenlace únicamente vinculará con la “home page”
	o página principal del Sitio Web pero no podrá reproducirla de
	ninguna forma ("inline", "links", "deep-
	links", "browser" o "border environment",
	copia de los textos, gráficos, etc.). </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">b)
	Quedará en todo caso prohibido, de acuerdo con la legislación
	aplicable y vigente en cada momento, establecer "frames" o
	marcos de cualquier tipo que envuelvan el Sitio Web y/o los
	Contenidos o permitan la visualización de parte o de la totalidad
	del Sitio Web y/o los Contenidos a través de direcciones de Internet
	distintas a las del Sitio Web y/o de los Contenidos y, en cualquier
	caso, cuando permitan la visualización de elementos del Sitio Web
	y/o los Contenidos conjuntamente con contenidos ajenos al Sitio Web
	y/o de los Contenidos de forma que:(i) induzca o sea susceptible de
	inducir a error, confusión o engaño a los usuarios sobre la
	verdadera procedencia de los elementos que se visualizan o los
	servicios que se utilizan;(ii) suponga un acto de comparación o
	imitación desleal;(iii) sirva para aprovechar la reputación de la
	marca y prestigio de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">;
	o,(iv) de cualquier otra forma, resulte prohibido por la legislación
	vigente. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">c)
	No se realizarán desde la página y/o aplicación que introduce el
	enlace ningún tipo de manifestación o indicación falsa, inexacta o
	incorrecta sobre </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	sus empleados, clientes o sobre la calidad de los servicios que
	presta. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">d)
	En ningún caso, se expresará ni se dará a entender en la página
	y/o aplicación donde se ubique el enlace que </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	ha prestado su consentimiento para la inserción del enlace o que de
	otra forma patrocina, colabora, verifica o supervisa los servicios
	del remitente. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">e)
	Está prohibida la utilización de cualquier marca denominativa,
	gráfica o mixta o cualquier otro signo distintivo de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	dentro de la página del remitente, salvo en los casos permitidos por
	la ley o expresamente autorizados por </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y siempre que se permita, en estos casos, un enlace directo con el
	Sitio Web y/o los Contenidos en la forma establecida en esta
	cláusula. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">f)
	La página y/o aplicación que establezca el enlace o el hiperenlace
	deberá cumplir fielmente con la ley y no podrá en ningún caso
	disponer o enlazar con contenidos propios o de terceros que:(i) sean
	ilícitos, nocivos o contrarios a la moral y a las buenas costumbres
	(incluyendo a título meramente ejemplificativo y sin limitación,
	contenidos pornográficos, violentos, racistas, etc.);(ii) induzcan o
	puedan inducir al usuario a la falsa creencia de que </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	suscribe, respalda, se adhiere o de cualquier manera apoya las ideas,
	manifestaciones o expresiones, lícitas o ilícitas, del
	remitente;(iii) resulten inapropiados o no pertinentes con la
	actividad de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	en atención al lugar, contenidos y temática de la página web y/o
	aplicación del remitente.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">g)
	La autorización para insertar un enlace o hiperenlace no presupone,
	en ningún caso, un consentimiento para reproducir los aspectos
	visuales y funcionales ("look and feel") de cualquier Sitio
	Web y/o Contenido de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	En especial, la autorización para la inserción de hiperenlaces del
	Sitio Web y/o de los Contenidos estará condicionada al respeto por
	la dignidad y la libertad humana. El sitio web en el que se
	establezca el hiperenlace no contendrá informaciones o contenidos
	ilícitos, contrarios a la moral y a las buenas costumbres y al orden
	público, así como tampoco contendrá contenidos contrarios a
	cualesquiera derechos de terceros. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">h)
	El establecimiento del enlace no implica en ningún caso la
	existencia de relaciones entre </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y el propietario de la página web y/o aplicación en la que se
	establezca, ni la aceptación y aprobación por parte de
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	de los contenidos o servicios allí ofrecidos puestos a disposición
	del público. </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	podrá solicitar, en cualquier momento y sin necesidad de aportar las
	razones de dicha solicitud, que se elimine cualquier enlace o
	hiperenlace al Sitio Web y/o a los Contenidos, estando obligado el
	responsable del sitio web y/o aplicación que publica el enlace a
	proceder de inmediato a su eliminación. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>11.
	USO DE "COOKIES" </b></font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	titular del presente Contenido informa a los usuarios de que puede
	utilizar “cookies” cuando un usuario navega por el Contenido.
	Puede obtener más información acerca de nuestro uso de "cookies"
	en el documento Política de Cookies. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>12.GARANTÍAS</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	declara que ha adoptado todas las medidas necesarias, dentro de sus
	posibilidades y del estado de la tecnología de la que dispone, para
	garantizar el funcionamiento del Sitio Web y de los Contenidos y
	evitar la existencia y transmisión de virus y demás componentes
	dañinos o maliciosos a los usuarios.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Si
	el usuario tuviera conocimiento de la existencia de algún contenido
	ilícito, ilegal, contrario a las leyes o que pudiera suponer una
	infracción de derechos de propiedad intelectual y/o industrial,
	deberá notificarlo inmediatamente a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	para que ésta pueda proceder a la adopción de las medidas oportunas</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>13.RESPONSABILIDADES</b></font></font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	queda exenta de cualquier tipo de responsabilidad por daños y
	perjuicios en cualquier concepto y naturaleza en los siguientes
	casos:</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">a)
	Por la imposibilidad o dificultades de conexión empleadas para
	acceder al Sitio Web y/o a los Contenidos, interrupciones del
	servicio, demoras, errores, mal funcionamiento del mismo
	independientemente de la clase de conexión o medio técnico
	utilizado por el usuario. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">b)
	Por la interrupción, suspensión o cancelación del acceso al Sitio
	Web y/o a los Contenidos, así como por la disponibilidad y
	continuidad del funcionamiento del Sitio Web y/o de los Contenidos o
	de los servicios y/o elementos de los mismos, cuando ello se deba a
	la interrupción del servicio por mantenimiento técnico del Sitio
	Web y/o de los Contenidos, o bien a una causa ajena al ámbito de
	control de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	o bien por los servicios de los prestadores de servicios de la
	información. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">c)Por
	actuaciones dolosas o culposas del usuario, o que tengan su origen en
	causas de fuerza mayor y cualesquiera otras que se escapen del
	control de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	</font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">d)
	Por ataques de los denominados "hackers" o terceros
	especializados a la seguridad o integridad del sistema informático,
	siempre que </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	d haya adoptado todas las medidas de seguridad existentes según sus
	posibilidades técnicas. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">e)
	Por los daños o perjuicios que pudieran causar la información,
	contenidos, productos y servicios prestados, comunicados, alojados,
	transmitidos, exhibidos u ofertados por terceros ajenos a
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	incluidos los prestadores de servicios de la sociedad de la
	información, a través de un sitio web al que pueda accederse
	mediante un enlace existente en este sitio. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">f)
	Por cualquier daño o perjuicio en el software o hardware del usuario
	que se derive del acceso al Sitio Web y/o a los Contenidos de
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o del uso de la información o elementos o aplicaciones en ellos
	contenidos. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">g)
	Por la idoneidad, fiabilidad, disponibilidad, oportunidad o exactitud
	de la información o servicios contenidos en su Sitio Web y/o en los
	Contenidos, ni por los daños directos o indirectos en relación con
	el uso de la información o elementos en ellos contenidos. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario del Sitio Web y/o de los Contenidos responderá personalmente
	de los daños y perjuicios de cualquier naturaleza causados a
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	directa o indirectamente, por el incumplimiento de cualquiera de las
	obligaciones derivadas de estas Condiciones Generales o, en su caso,
	de las Condiciones Particulares. En cualquier caso, sea cual fuere la
	causa, </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	no asumirá responsabilidad alguna, ya sea por daños directos o
	indirectos, daño emergente ni por lucro cesante. </font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	usuario será el único responsable de las infracciones en que pueda
	incurrir o de los perjuicios que pueda causar por el uso del Sitio
	Web y/o de los Contenidos de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	quedando ésta exonerada de cualquier responsabilidad. El usuario es
	el único responsable frente a cualquier reclamación o acción
	legal, judicial o extrajudicial, iniciada por terceras personas
	contra </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o bien contra el usuario basada en la utilización por éste del
	servicio, o por la información que haya podido remitir a
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	por cualquier medio. El usuario asume cuantos gastos, costes e
	indemnizaciones sean irrogados a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	con motivo de tales reclamaciones o acciones legales.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>14.
	GENERAL </b></font></font></font>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	acceso, los contenidos y servicios ofrecidos a través del Sitio Web
	y/o a los Contenidos tienen, en principio, una duración indefinida,
	salvo que se disponga lo contrario en las Condiciones Generales, las
	Condiciones Particulares o en la legislación aplicable en cada
	momento. </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se reserva no obstante, sin necesidad de previo aviso y en cualquier
	momento, el derecho a suspender, denegar o restringir temporal o
	definitivamente el acceso a su Sitio Web y/o a sus Contenidos, a
	efectuar las modificaciones que considere oportunas en el Sitio Web
	y/o en los Contenidos, en los servicios o informaciones ofrecidas, en
	la presentación o localización de los mismos así como en las
	Condiciones Generales. Todo ello sin que ello dé lugar a
	indemnización alguna al usuario.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Cualquier
	cláusula o disposición de las presentes Condiciones Generales que
	sea o devenga ilegal, inválida o inexigible será excluida y será
	considerada inaplicable en lo que alcance a tal ilegalidad, invalidez
	o inexigibilidad, y será sustituida por otra que se asemeje lo más
	posible a la anterior, pero que no afecte o perjudique a las
	restantes disposiciones, las cuales quedarán al margen de cualquier
	cláusula o disposición ilegal, inválida o inexigible y
	permanecerán, por el contrario, plenamente vigentes.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	excluye cualquier tipo de garantía, y, por tanto, queda libre de
	toda responsabilidad derivada de los puntos expresados con
	anterioridad.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Toda
	la información que se reciba en este Sitio Web y/o a través de los
	Contenidos se considerará cedida a </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	a título gratuito.</font></font></p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p class="western" align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	email o correo electrónico no será considerado como medio válido a
	los efectos de presentación de reclamaciones por razón de
	contenidos. Para ello, deberán dirigirse por escrito al Departamento
	de Asesoría Jurídica de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	sito en la </font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Torre
	Siglo XXI. Paseo Fluvial N15, P11. 06011 Badajoz, España</font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	que indicará en cada caso los cauces a seguir.</font></font></p>
</div>

<div *ngIf="informationType === '/privacy-policy'">
	<style type="text/css">
	<!--
		@page { margin-left: 1.18in; margin-right: 1.18in; margin-top: 0.98in; margin-bottom: 0.98in }
		P { margin-bottom: 0.08in; direction: ltr; font-size: 12pt; widows: 2; orphans: 2 }
	-->
	</style>


	<p style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="5"><b>Política
	de Privacidad de </b></font></font><font face="Montserrat SemiBold, serif"><font size="5"><b>ecaptureDtech</b></font></font></p>
	<p style="margin-bottom: 0in"><br>
	</p>
	<p style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>1.
	¿Para qué sirve esta Política?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Para
	que puedas consultar de forma rápida y concisa cómo utilizamos los
	datos personales que tenemos sobre ti. Puedes consultar información
	adicional sobre este y los demás apartados de la Política.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>2.
	¿Quiénes somos?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">eCapture
	Research and Development, S.L, (en adelante </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">)
	trata tus datos personales como responsable del tratamiento, es
	decir, nos hacemos cargo conjuntamente de cómo utilizar y proteger
	tus datos personales.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>3.
	¿Dónde obtenemos tus Datos?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol type="i">
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">De
	ti mismo, ya que nos facilitas cierta información al crearte una
	cuenta de usuario; </font></font>
	</p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">de
	la información que obtenemos del uso que realizas de la plataforma
	web, app y pagina web de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y de redes sociales. </font></font>
	</p>
	</li></ol>
	<p align="JUSTIFY" style="margin-left: 0.75in; margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-left: 0.75in; margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>4.
	¿Para qué utilizamos tus Datos?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Para
	(i) prestarte nuestro servicio de inventario de señales de trafico,
	baches y líneas(Tratamiento de imágenes) (el “Servicio”) con
	nuestra plataforma eyesNroad; (ii) sólo si lo deseas, para enviarte
	noticias y promociones de </font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	basadas en tu perfil; para (iii) mejorar nuestro servicio; para (iv)
	prevenir fraudes y para (v) consultar tu opinión sobre
	</font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	</font></font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>5.
	¿Durante cuánto tiempo?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Hasta
	que elimines tu cuenta de usuario o solicites la cancelación de tus
	datos personales. El envío de promociones de terceros se producirá
	sólo mientras tengas cuenta en nuestra plataforma web. </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>6.
	¿Por qué es lícito que los utilicemos?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Por
	varios motivos. En primer lugar, porque necesitamos utilizarlos para
	ejecutar los Términos y Condiciones que aceptas con nosotros al
	crearte una cuenta; de lo contrario, no podríamos prestarte el
	Servicio. </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	segundo lugar, te enviamos noticias y promociones sobre </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	o terceros sólo cuando nos lo autorices. </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Por
	último, tratamos de mejorar nuestro Servicio, de prevenir el fraude
	y realizamos estudios de mercado porque consideramos que tenemos un
	interés legítimo en hacerlo y que también son beneficiosas para
	ti.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>7.
	¿Compartimos tus Datos con terceros?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Sí.
	Compartimos tus datos personales (i) con otras entidades del grupo
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	(iii) con proveedores externos que nos ayudan con diferentes
	cuestiones relacionadas con el Servicio y (iv) con autoridades u
	organismos públicos. En algunos casos, estos terceros están
	ubicados fuera de la Unión Europea, pero las transferencias de tus
	Datos Personales se realizan con garantías adecuadas: o hemos
	firmado con estas entidades Cláusulas Contractuales Tipo aprobadas
	por la Comisión Europea o bien están adheridas al Privacy Shield.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>8.
	¿Cuáles son tus derechos?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Puedes
	solicitarnos el acceso, la rectificación, la supresión o la
	portabilidad de tus datos personales. También puedes presentar una
	reclamación ante las autoridades de protección de datos
	competentes. En algunos casos, también puedes a oponerte a que
	utilicemos tus datos o pedirnos que limites su uso. </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>9.
	¿Modificaremos esta Política?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Posiblemente
	lo hagamos en el futuro, podrán modificar de forma unilateral y sin
	previo aviso, la política de privacidad y cookies, entiéndase
	aceptadas por los usuarios del Sitio Web.  No obstante, el usuario
	del Sitio Web y/o de los contenidos deberán acceder a estas
	políticas de privacidad y cookies de los servicios del Sitio Web y/o
	de los contenidos que utilice, de forma periódica para tener
	conocimiento de la actualización de las mimas, si la hubiera. </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>10.
	¿Para qué sirve esta Política de Privacidad?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	el grupo </font></font></font><font color="#000000"><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	estamos comprometidos con prestarte un servicio de inventario de
	señales de trafico, baches y líneas (Tratamiento de imágenes) a
	través de nuestra plataforma web o App, así como la visualización
	de los citados datos directamente en nuestra plataforma/app. Además,
	también nos preocupa la privacidad de tu información personal.
	Queremos que conozcas y tengas siempre el control sobre tus datos
	</font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">personales.
	Para ello, ponemos a tu disposición esta Política de Privacidad (la
	"Política de Privacidad"), en la que puedes consultar en
	detalle, pero de forma fácil y transparente, las características
	del uso que realizamos sobre tus datos personales para poder
	prestarte nuestro Servicio y para otros fines.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><a name="_Hlk71280467"></a>
	<font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Puedes
	acceder a esta Política de Privacidad en cualquier momento, ya que
	se encuentra a tu disposición en el apartado "Políticas de
	Privacidad" de la plataforma web de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	https://eyesnroad.com (el “Sitio Web”) y de la aplicación móvil
	(la "Aplicación"). </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	el Sitio Web, el apartado "Políticas de Privacidad" se
	encuentra en la parte inferior, mientras que en la Aplicación es
	accesible desde el menú que puedes desplegar tocando en el cuadrado
	situado en la parte superior izquierda de la pantalla. Cuando
	utilizamos el término "Plataforma", nos referimos tanto al
	Sitio Web como a la Aplicación.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>11.
	¿Quién es el Responsable del tratamiento de tus Datos Personales?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	Responsables del Tratamiento de tus Datos Personales es eCapture
	Research and Development, S.L. A continuación puedes encontrar más
	información sobre nosotros:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ul>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><span lang="en-US">eCapture
	Research and Development, S.L., Sociedad Española ("</span></font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><span lang="en-GB"><b>ecaptureDtech</b></span></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><span lang="en-US">
	")</span></font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Dirección
	postal: Torre Siglo XXI. Paseo Fluvial N15, P11. 06011 Badajoz,
	España</font></font></p>
	</li></ul>
	<p align="JUSTIFY" style="margin-left: 0.65in; margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Es
	decir, </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	es responsables de tus Datos Personales. Esto se debe a que decidimos
	cómo utilizamos y protegemos tus Datos Personales. Las funciones y
	las responsabilidades de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	d a la hora de usar y proteger tus Datos Personales están
	perfectamente definidas. Si quieres, puedes solicitarnos que te
	facilitemos los aspectos esenciales del mismo a través del Centro de
	Ayuda o Chat Online situado en el Sitio Web y en la Aplicación.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>12.
	¿Dónde obtenemos tus Datos Personales?¿Qué Datos vamos a utilizar
	exactamente?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Ten
	en cuenta que, para que puedas disfrutar del Servicio que te
	ofrecemos en </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	utilizaremos algunos datos sobre ti que obtenidos a través de
	diferentes formas y en diferentes momentos. A continuación, puedes
	verlo en detalle:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	los datos personales que tú mismo nos entregas al crearte una
	cuenta de usuario: nombre y apellidos, contraseña, dirección de
	correo electrónico, número de teléfono, dirección postal, país,
	información bancaria y fecha de nacimiento.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">También
	utilizaremos otra información sobre ti que podamos llegar a conocer
	gracias al uso que realizas del Servicio: tu ubicación; tu
	dirección IP; tu navegación por la Plataforma (fechas y horas en
	las que accedes, clics que realizas, etc.) y el hardware, software y
	navegador que utilizas.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Por
	último, obtendremos información de diferentes redes sociales, que
	nos la facilitarán en caso de que lo hayas autorizado aceptado sus
	correspondientes términos y condiciones.</font></font></p>
	</li></ol>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Para
	referirnos a toda la información anterior y a cualquier otra que
	tengamos sobre ti utilizamos el término "Datos Personales".</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>13.
	¿Para qué vamos a utilizar tus Datos Personales?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales para las siguientes finalidades:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">1.
	Prestarte el Servicio</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	tratamiento de tus Datos Personales es necesario para que podamos
	prestarte nuestro Servicio de </font></font><font color="#ff0000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">inventario
	de señales de trafico, baches y líneas </font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">(Tratamiento
	de imágenes). De lo contrario, no podrás disfrutar del mismo. La
	prestación del Servicio comprende las siguientes cuestiones:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ul>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Gestionar
	la creación de tu cuenta de usuario y permitirte utilizarla o
	modificarla. Para disfrutar del Servicio, es necesario que crees una
	cuenta y que te identifiques como usuario. Podrás modificar los
	Datos Personales que nos hayas facilitado en cualquier momento a
	través del apartado "Mi cuenta" incluido en el menú
	desplegable.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Gestionar
	los pagos de </font></font><font color="#ff0000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">tus
	proyectos de inventario de señaes de trafico, baches y líneas </font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">a
	través del medio de pago que hayas prestablecido y seleccionado.
	Esta funcionalidad permite que no tengas que incluir un medio de
	pago cada vez contraten un servicio, pero también implica que
	podamos conocer tu información bancaria. No obstante, ten en cuenta
	que no utilizaremos los datos sobre tus medios de pago para ninguna
	finalidad diferente a la gestión de los pagos de tus compras.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Permitirte
	consultar un histórico de toda la actividad realizada en nuestra
	plataforma web o App. Para ello, simplemente debes acceder al
	apartado "Historial de Pagos " incluido en el menú
	desplegable.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Facilitarte
	a través de la dirección de correo electrónico que nos hayas
	proporcionado la información o documentación que sea generada por
	nuestra plataforma web y que sea correspondiente a tu usuario.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Ofrecerte
	la mejor atención y asistencia. A través de nuestro Centro de
	Ayuda y Chat online te ofrecemos asistencia en cualquier momento
	para eventuales dudas, comentarios, sugerencias o incidencias
	relativas al Servicio que nos quieras plantear. La referida ayuda
	solo puede ser facilitada si disponemos de tus Datos Personales.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Cualquier
	otra cuestión relacionada con la prestación del Servicio.</font></font></p>
	</li></ul>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">2.
	Realizar acciones de marketing</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales para enviarte noticias, productos y promociones
	relacionados con </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y con terceros relacionados con los sectores relativos a productos y
	consumos. A tal fin, analizaremos tu histórico de actividad, los
	importes incurridos, las promociones que has utilizado, la frecuencia
	con que utilizas el Servicio, tu perfil sociológico y tus intereses
	personales y crearemos un perfil sobre ti para que las promociones
	que te enviemos sean personalizadas, de forma que se adapten en cada
	momento a tus necesidades y preferencias personales. Ten en cuenta
	que puedes pedirnos en cualquier momento que dejemos de analizar tu
	perfil, aunque ello supondrá que no recibirás más noticias,
	ofertas y promociones. El perfil que creemos sobre ti no será
	utilizado para ninguna finalidad diferente a la personalización de
	nuestras promociones.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">También
	te enviaremos comunicaciones de terceros sobre los sectores relativos
	a productos y consumos que creemos que puedan ser de tu interés
	según tu perfil si nos lo consientes de forma específica. </font></font>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	cualquier caso, ten en cuenta que podrás darte de baja de dichas
	comunicaciones en cualquier momento. Para ello, simplemente tendrás
	que hacer clic en el enlace “Darte de baja” contenido en
	cualquiera de las comunicaciones.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">3.
	Mejorar nuestro servicio</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	trabajamos permanentemente en el perfeccionamiento de la Aplicación
	y el Sitio Web y, por ese motivo, realizamos pruebas, investigaciones
	y estudios analíticos y desarrollamos nuevos productos que acaban
	redundando en la mejora de la calidad del Servicio. Nos permiten
	optimizarlo, hacerlo más funcional y adaptarlo a tus necesidades (al
	ofrecerte, por ejemplo, la opción de introducir tus preferencias, la
	configuración elegida o tus lugares frecuentes). Tales trabajos
	requieren en muchas ocasiones el uso de algunos de tus Datos
	Personales.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">4.
	Prevenir fraudes</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Para
	prevenir potenciales fraudes contra ti y contra </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	hemos implantado medidas que hagan de nuestra Plataforma un lugar
	seguro. Por ejemplo, te solicitaremos que valides los pagos
	introduciendo un código que recibirás por SMS. Asimismo, cuando
	detectemos la existencia de cargos o información irregular, nos
	pondremos en contacto contigo para asegurarnos de que todo es
	correcto. En algunos casos, tendremos que revelar información
	relativa a tus compras a las entidades emisoras de tus medios de pago
	para resolver posibles conflictos.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">5.
	Consultarte tu opinión sobre </font></font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Nos
	resulta muy útil conocer cuál es tu opinión sobre </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	para poder tomar decisiones estratégicas que nos conviertan en una
	compañía alineada con los intereses y preocupaciones de nuestros
	usuarios. Por ello, en algunos momentos podremos pedirte que nos
	respondas a una sencilla encuesta para conocer cuál es tu percepción
	sobre nosotros.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Ten
	en cuenta que nos interesa conocer la opinión de nuestros usuarios
	de forma estadística. Por ello, cuando recibamos tu opinión
	procederemos a pseudonimizarla, es decir, tus respuestas únicamente
	se encontrarán asociadas a un código. Posteriormente, entregaremos
	la información pseudonimizada a la entidad que nos ayuda a realizar
	estos estudios para que pueda analizarla junto a las opiniones de
	otros muchos usuarios y pueda elaborar un estudio estadístico sobre
	la imagen y percepción que tenéis sobre </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Por
	supuesto, no tendrás que darnos tu opinión si no quieres.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">6.
	Usuarios de clientes de empresa</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	aquellos casos en que hagas uso del Servicio a través de la cuenta
	de empresa que tu empresa tenga con </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	compartiremos los datos relativos a </font></font><font color="#000000"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">tus
	proyectos y datos(tratamiento </font></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">de
	imágenes) con tu empleador.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>14.
	¿Durante cuánto tiempo conservaremos tus Datos Personales?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">El
	periodo de conservación de tus Datos Personales depende de cada una
	de las finalidades para las los utilizamos. Puedes consultarlas en
	detalle en el apartado 4. En el siguiente cuadro te indicamos durante
	cuánto tiempo o hasta qué momento conservaremos tus datos en
	relación con cada una de las finalidades:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">1.
	Prestarte el Servicio</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales hasta que decidas dejar de utilizar nuestro
	Servicio, para lo que deberás eliminar tu cuenta de usuario.
	Mientras no te des de baja, continuaremos tratando tus Datos
	Personales para este fin. Ten en cuenta que, si existe algún asunto
	pendiente de resolver relacionado con el Servicio, trataremos de
	solucionarlo antes de que puedas darte de baja.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">2.
	Realizar acciones de marketing</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales hasta que nos solicites que dejemos de hacerlo,
	independientemente de que continúes utilizando nuestro Servicio o te
	hayas dado de baja. Ten en cuenta que puedes pedirnos que dejemos de
	enviarte noticias, ofertas y promociones personalizadas en cualquier
	momento; simplemente deberás hacer clic en el enlace “Darte de
	baja” contenido en cualquiera de nuestras comunicaciones.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">3.
	Mejorar nuestro servicio</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales hasta que decidas dejar de utilizar nuestro
	Servicio, para lo que deberás eliminar tu cuenta de usuario.
	Mientras no te des de baja, continuaremos tratando tus Datos
	Personales para este fin.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">4.
	Prevenir fraudes</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Al
	igual que en el caso anterior, utilizaremos tus Datos Personales
	hasta que decidas dejar de utilizar nuestro Servicio, para lo que
	deberás eliminar tu cuenta de usuario. Mientras no te des de baja,
	continuaremos tratando tus Datos Personales para este fin.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">5.
	Consultarte tu opinión sobre </font></font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales hasta que la entidad que nos ayuda a realizar
	los estudios estadísticos nos envíe el correspondiente estudio.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Cuando
	lleguen los momentos indicados en el cuadro anterior, no eliminaremos
	directamente tus Datos Personales; los conservaremos debidamente
	bloqueados y protegidos durante el tiempo en que pudieran surgir
	responsabilidades derivadas del tratamiento o durante los periodos
	exigidos por cualquier normativa aplicable. Durante ese tiempo, tus
	Datos Personales no serán tratados para ninguna finalidad que no sea
	atender tales posibles responsabilidades o cumplir dichas
	obligaciones legales. En consecuencia, sólo podrán acceder a los
	mismos miembros con especial capacidad de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y los miembros del departamento informático en para atender dichas
	responsabilidades u obligaciones legales. Una vez prescriban estas
	posibles acciones y expiren las obligaciones legales mencionadas,
	procederemos a eliminar tus Datos Personales de forma definitiva sin
	mayor dilación.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>15.
	¿Por qué es lícito que utilicemos tus Datos Personales?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	solamente utilizamos tus Datos Personales de forma lícita, tal como
	nos exige la normativa aplicable en la materia. Para ello,
	necesitamos contar con una base jurídica suficiente. A continuación
	te indicamos cuál es la base jurídica, es decir, por qué motivo es
	lícito, que utilicemos tus Datos Personales para cada una de las
	finalidades descritas en el apartado anterior.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">1.
	Prestarte el Servicio</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizar
	tus Datos Personales es necesario para poder ejecutar el contrato que
	nos une contigo desde el momento en que aceptas nuestros Términos y
	Condiciones. De lo contrario, no podrás disfrutar del Servicio.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">2.
	Realizar acciones de marketing</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Utilizaremos
	tus Datos Personales para enviarte noticias, ofertas y promociones
	basadas en tu perfil únicamente si nos has dado tu consentimiento
	para ello. Las comunicaciones que te enviemos podrán realizarse a
	través de correo electrónico, SMS o incluso a través de
	notificaciones push. Recuerda que puedes pedirnos en cualquier
	momento que dejemos de enviarte estas comunicaciones personalizadas.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">3.
	Mejorar nuestro servicio</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Consideramos
	que en </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	tenemos un interés legítimo para realizar pruebas, investigaciones
	y estudios analíticos que mejoren la calidad de nuestro Servicio,
	nos permitan hacerlo más funcional y adaptarlo a tus necesidades. En
	nuestra opinión, este tratamiento también te beneficia
	directamente, ya que podrás disfrutar de un Servicio que resuelva
	con mayor precisión tus necesidades de movilidad.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">4.
	Prevenir fraudes</font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">También
	entendemos que en </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	tenemos un interés legítimo para tratar de prevenir potenciales
	fraudes relacionados con el Servicio. Este tratamiento es positivo
	para </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	y también para ti, ya que nos permitirá utilizar procedimientos que
	traten de evitar utilizaciones fraudulentas del Servicio. Por
	ejemplo, que un tercero trate de utilizar tus datos bancarios o que
	se realicen cargos no relacionados con tus viajes, etc.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">5.
	Consultarte tu opinión sobre </font></font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Creemos
	que tenemos un interés legítimo para conocer la percepción que
	tienes sobre </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	ya que nos permitirá tomar decisiones estratégicas que se adapten a
	las necesidad y preocupaciones de todos nuestros usuarios. Además,
	entendemos que la utilización de tus Datos Personales para esta
	finalidad es compatible con la prestación del Servicio.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>16.
	¿Compartiremos tus Datos Personales con terceros?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Sí.
	Para poder prestarte el Servicio con la máxima calidad necesitamos
	compartir tus Datos Personales con nuestro personal, con otras
	entidades del grupo </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	con proveedores externos que nos ayudan con diferentes cuestiones
	relacionadas con el Servicio y con terceras partes. A continuación
	te indicamos quiénes son estas entidades:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Entidades
	del grupo </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	Necesitamos ceder o permitir acceso a tus Datos Personales a
	diferentes entidades del grupo </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	para poder desarrollar correctamente el Servicio. Las entidades que
	componen el grupo </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	se detallan a continuación:</font></font></p>
	</li></ol>
	<p align="JUSTIFY" style="margin-bottom: 0in">         <font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Cima
	Corporate (Qombas 2009 Ns SL)</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol start="2">
	<li><p align="JUSTIFY" style="margin-bottom: 0in"> <font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Entidades
	que nos ayudan con diferentes cuestiones relacionadas con el
	Servicio. Nos aseguramos de que todos nuestros proveedores que
	pueden tener acceso a tus Datos Personales actúen de manera
	confidencial, leal y cumpliendo escrupulosamente la normativa
	aplicable sobre protección de datos. A tal fin, les exigimos que
	formalicen con nosotros acuerdos específicos que regulan la
	utilización por su parte de tus Datos Personales. Algunos de
	nuestros principales proveedores son los siguientes:</font></font></p>
	</li></ol>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ul>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Entidades
	que prestan servicios de procesamiento de datos. En </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	recurrimos a terceras entidades para ejecutar y procesar los pagos
	que realices en relación con el Servicio. Estas entidades recopilan
	información de tu tarjeta de crédito o débito (como el tipo de
	tarjeta y la fecha de vencimiento), además de otra información
	financiera que necesitamos para procesar tu pago. También podemos
	recopilar información como tu código postal, número de teléfono
	móvil y detalles sobre tu historial de transacciones. El
	tratamiento de todos estos es necesario para procesar el pago. Por
	último, nos proporcionan otra información sobre ti como el token
	único que te permite realizar compras adicionales utilizando
	información almacenada previamente.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Entidad
	prestadora de servicio de computación en la nube. Esta entidad nos
	ayuda a procesar tus imágenes y metadatos y otra información
	relacionada con el Servicio de forma segura.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Entidad
	prestadora de servicio de almacenamiento. Esta entidad nos ayuda a
	almacenar tus Datos Personales y otra información relacionada con
	el Servicio de forma segura.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Entidad
	prestadora de servicio de realización de encuestas o estudios de
	mercado. Nos apoyamos en esta entidad para realizar estudios
	estadísticos y de mercado sobre la valoración de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	Como te decíamos en el apartado IV, todos los Datos Personales que
	les podamos compartir se encuentran pseudonimizados, por lo que a la
	hora de realizar sus análisis no sabrán qué persona se encuentra
	detrás de cada opinión.</font></font></p>
	</li></ul>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol start="3">
	<li><p align="JUSTIFY" style="margin-bottom: 0in"> <font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Autoridades
	u organismos públicos para el desarrollo de sus funciones. En
	algunas ocasiones, la normativa nos obliga a ceder tus Datos
	Personales a autoridades u organismos públicos para que puedan
	desarrollar sus funciones. Por ejemplo, autoridades fiscales o
	judiciales. De este modo, los utilizarán para sus propios fines.</font></font></p>
	</li></ol>
	<p align="JUSTIFY" style="margin-left: 0.2in; margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-left: 0.2in; margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Sin
	perjuicio de que el país en el que te encuentres esté situado fuera
	del Espacio Económico Europeo, queremos informarte de que algunas de
	las entidades anteriores se encuentran situados o pueden acceder a
	tus Datos Personales desde países que, a juicio de la Comisión
	Europea, no proporcionan un nivel de protección para los mismos
	similar al que existe en la Unión Europea. Sabemos que estos países
	no proporcionan un nivel equiparable de protección porque la
	Comisión Europea no ha aprobado una "decisión de adecuación".
	En estos casos, las transferencias de tus Datos Personales se
	realizan en todo caso con las garantías adecuadas para proteger su
	seguridad:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ul>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">A
	veces hemos firmado Cláusulas Contractuales Tipo aprobadas por la
	Comisión Europea. Puedes consultar su contenido en el siguiente
	enlace</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	otras ocasiones, nuestros proveedores se han adherido al Privacy
	Shield, sobre el que puedes consultar más información en el
	siguiente enlace</font></font></p>
	</li></ul>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>17.
	¿Cuáles son tus derechos en cuestiones de Protección de datos?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Como
	usuario de </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">,
	dispones de una serie de derechos para que puedas decidir y controlar
	en todo momento cómo utilizamos tus Datos Personales. Puedes
	ejercitar estos derechos en cualquier momento y de forma gratuita
	(salvo que tu solicitud sea excesiva o infundada -será infundada,
	por ejemplo, si acabas de ejercitar el mismo derecho ante
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	muy recientemente; en tal caso podremos rechazar tu solicitud o
	cobrarte, siempre previo aviso, un canon). Para ejercitar uno de tus
	derechos, simplemente debes hacerlo a través del Centro de Ayuda y
	Chat Online del Sitio Web y en la Aplicación. Para que podamos
	atender tu solicitud, necesitamos asegurarnos de que realmente eres
	tú, por lo que deberás facilitarnos copia de tu documento de
	identidad; también te agradeceríamos que nos indicases el derecho
	que deseas ejercitar. Si tu solicitud no reúne los requisitos
	anteriores, te pediremos que la subsanes.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">En
	particular, dispones de los siguientes derechos:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ol>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Acceso.
	Puedes solicitarnos que te indiquemos si estamos utilizando o no tus
	Datos Personales; en caso de que sí los estemos utilizando, también
	te trasladaremos información adicional como qué datos personales
	tenemos sobre ti, para qué los estamos utilizando, las entidades
	con las que los compartimos, el periodo durante el que vamos a
	conservar tus datos o si estamos realizando perfiles, entre otras
	cuestiones.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Rectificación.
	Puedes pedirnos que modifiquemos alguno de los Datos Personales que
	tenemos sobre ti para que sean exactos y estén actualizados. De
	hecho, ten en cuenta que debes mantener actualizados en todo momento
	los Datos Personales que figuran en tu cuenta de usuario. Puedes
	hacerlo en el apartado "Mi cuenta" accesible desde el menú
	desplegable.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Supresión.
	Puedes solicitarnos que eliminemos los Datos Personales que tenemos
	sobre ti (i) cuando ya no sean necesarios para la finalidad para la
	que los utilizamos, (ii) cuando nos retires tu consentimiento, en el
	caso de que sólo los tratemos para desarrollar finalidades basadas
	en el mismo.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Limitación.
	Puedes pedirnos que restrinjamos temporalmente la utilización de
	tus Datos Personales (i) cuando consideres que tus datos son
	inexactos, hasta que los verifiquemos o actualicemos (ten en cuenta
	que, mientras tanto, no podrás disfrutar del Servicio); (ii) cuando
	ya no los necesitemos para llevar a cabo las finalidades indicadas
	en el apartado 4 anterior pero tú prefieras que los conservemos
	para que puedas ejercitar o defenderte ante reclamaciones y (iii)
	cuando te opongas a que utilicemos tus datos para alguna de las
	finalidades recogidas en el número 2 del apartado 4 de esta
	Política de Privacidad, mientras valoramos si el interés legítimo
	que tenemos en </font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">
	prevalece sobre tu derecho a oponerte a que los utilicemos.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Portabilidad.
	Puedes solicitarnos que te entreguemos directamente o que
	entreguemos a otra entidad, siempre que sea posible técnicamente,
	un archivo estructurado, de uso común y legible por un sistema
	informático, que contenga los Datos Personales relativos a los
	viajes que has realizado con el límite indicado en el apartado 4 de
	esta Política, así como cualquier otra información relacionada
	con la prestación del Servicio.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Reclamación.
	Puedes presentar una reclamación ante la autoridad de control
	competente en materia de protección de datos. En tu caso, las
	autoridades competentes son las siguientes:</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Agencia
	Española de Protección de Datos:</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Oposición.
	Puedes oponerte a que utilicemos tus datos para alguna de las
	finalidades recogidas en el número 2 del apartado 4 de esta
	Política de Privacidad. Queremos recordarte especialmente que
	puedes pedirnos que dejemos de analizar tu perfil para enviarte
	comunicaciones promocionales.</font></font></p>
	</li></ol>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>18.
	¿Actualizaremos esta Política de Privacidad?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Es
	posible. Ten en cuenta que, si por cualquier motivo decidimos
	realizar algún cambio en nuestra Política de Privacidad, te
	informaremos puntualmente antes de que los cambios comiencen a ser
	aplicables. De esta manera podrás conocer cómo pretendemos utilizar
	tus Datos Personales antes de que comencemos a hacerlo.</font></font></p>
</div>

<div *ngIf="informationType === '/cookie-policy'">
	<style type="text/css">
	<!--
		@page { margin-left: 1.18in; margin-right: 1.18in; margin-top: 0.98in; margin-bottom: 0.98in }
		P { margin-bottom: 0.08in; direction: ltr; widows: 2; orphans: 2 }
		A:link { color: #0563c1; so-language: zxx }
	-->
	</style>


	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="5"><b>Política
	de cookies de </b></font></font><font face="Montserrat SemiBold, serif"><font size="5"><b>ecaptureDtech</b></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>¿Qué
	son las Cookies?</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	Cookies son pequeños archivos de texto que se almacenan de forma
	local en la memoria de los navegadores web. Esto contribuye a ofrecer
	un mejor servicio a nuestros usuarios. A modo de ejemplo, el uso de
	cookies nos permite entender como un usuario navega en nuestra web,
	que interacciones lleva a cabo, recordar los detalles de acceso y
	almacenar las preferencias de navegación. En los próximos párrafos
	se explica cómo utilizamos las cookies para asegurarnos que nuestros
	servicios funcionan correctamente, mejorar la experiencia de uso,
	analizar datos y prevenir el fraude.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>1.
	Cómo utilizamos las Cookies</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Las
	Cookies son un elemento importante en nuestra plataforma y App, ya
	que nos ayudan a ofrecer un servicio mejor y más cercano.
	Modificamos las cookies a lo largo del tiempo según mejoramos
	nuestro servicio. Estos son los principales casos de uso:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<ul>
	<li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Acreditación.
	Gracias al uso de cookies podemos recordar tus credenciales y estado
	del login, para facilitar una navegación y acceso rápido a tu
	cuenta.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Seguridad.
	A través de las especificaciones de los equipos y navegadores
	empleados para acceder a nuestra web podemos brindarte una
	experiencia más segura.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Servicios
	y características de la web. Utilizamos las cookies para recordar
	tus preferencias en la navegación y uso de nuestros servicios. Esto
	significa que no es necesario especificar estas preferencias y
	ajustes cada vez que visitas nuestra web. A modo de ejemplo, si has
	configurado Inglés como tu idioma preferido, mostraremos siempre el
	contenido en dicho idioma para ofrecerte un mejor servicio.</font></font></p>
	</li><li><p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Analizar
	y mejorar la experiencia de uso. Gracias al uso de cookies podemos
	comprender mejor cómo nuestros usuarios llegan a nuestra web y nos
	proporciona información sobre el uso y comportamiento general en la
	misma. Esto nos permite analizar qué áreas podemos mejorar, para
	dar un servicio más completo y una mejor experiencia de uso.</font></font></p>
	</li></ul>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>2.
	Uso de otras tecnologías</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in">    <font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Pixel
	tags. Los pixel tags (también conocidos como beacons) son empleados
	junto con otros servicios para, entre otras cosas, medir el éxito de
	nuestras campañas de marketing y recabar estadísticas acerca del
	uso de nuestros servicios, conversión y ratios de respuesta.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in">    <font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Herramientas
	de análisis web. Utilizamos Google Analytics y otras herramientas de
	terceros, que emplean cookies y tecnologías similares, para recabar
	y analizar información sobre el uso de nuestros servicios. Gracias a
	esto podemos identificar tendencias y cambios para mejorar la
	experiencia de uso y servicio al usuario.</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font color="#131bbc"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt"><b>3.
	Gestionar las Cookies</b></font></font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Puedes
	gestionar el uso de cookies directamente desde los ajustes de tu
	navegador web. Esto incluye borrar y/o deshabilitar las cookies de
	</font></font><font face="Montserrat SemiBold, serif"><font size="2" style="font-size: 10pt"><b>ecaptureDtech</b></font></font><font face="Montserrat, serif"><font size="2" style="font-size: 10pt">.
	Los siguientes enlaces pueden servir como referencia para ello:</font></font></p>
	<p align="JUSTIFY" style="margin-bottom: 0in"><br>
	</p>
	<p align="JUSTIFY" style="margin-bottom: 0in">    <font face="Montserrat, serif"><font size="2" style="font-size: 10pt">Chrome
	/  Explorer / Safari / Firefox / Opera</font></font></p>
	<p style="margin-bottom: 0in"><br>
	</p>
</div>
