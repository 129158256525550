import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Project } from 'src/app/models/projects/project';

/** Componente para mostrar la barra de progreso de un proyecto */
@Component({
  selector: 'app-project-progress-bar',
  templateUrl: './project-progress-bar.component.html',
  styleUrls: ['./project-progress-bar.component.scss']
})
export class ProjectProgressBarComponent implements OnInit, OnChanges {

  /** Carga el proyecto */
  @Input() project: any;
  /** Altura */
  @Input() height: number;
  /** Porcentaje de progreso */
  @Input() percent: string;

  ngOnChanges()
  {

  }

  ngOnInit()
  {

  }

}
