import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

/**
 * Componente de pago correcto.
 */
@Component({
  selector: 'app-payment-ok',
  templateUrl: './payment-ok.component.html',
  styleUrls: ['./payment-ok.component.scss']
})
export class PaymentOkComponent implements OnInit {

  /**
   * Constructor.
   * @param activatedRoute
   */
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
  });
   }

   /**
    * Inicialización del componente.
    */
  ngOnInit() {
  }

}
