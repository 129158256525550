import {Pipe, PipeTransform} from '@angular/core';

/**
 * Dividir una cadena en un array de cadenas usando un separador.
 */
@Pipe({
    name: 'split',
})
export class SplitPipe implements PipeTransform {
  /**
   * Constructor.
   */
  constructor() {}

  /**
   * Dividir una cadena en un array de cadenas usando un separador.
   * @param value
   * @param length
   * @returns
   */
  public transform(value: string, length?: number): string {
    if (value && value.length > length) {
      return value.slice(0, length) + '...';
    }
    return value;
  }
}
