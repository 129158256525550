import { Component, Input } from "@angular/core";

/** Componente de la barra de navegación */
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  /** Revisa el background */
  @Input() navbarBackgroundBlue = true;
  /** Revisa si la variable bigHeader está activa */
  @Input() bigHeader = false;
}
