import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
  ],
  exports: [
    FlexLayoutModule
  ],
  providers: [],
  imports: [
    FormsModule,
    FlexLayoutModule
  ]
})
export class UiModule { }
