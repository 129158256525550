import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../core/services/api-access/rest-client.service';

/**
 * Servicio de acceso a la API de contraseña olvidada.
 */
@Injectable({ providedIn: 'root' })
export class ForgotPasswordRemoteService {
  /**
   * URL del servicio.
   */
  private URL = '/forgot-password';

  /**
   * Constructor.
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Contraseña olvidada.
   * @param login
   * @returns
   */
  forgotPassword(login): Observable<any> {
    return this.restClientService.post(`${this.URL}/email`, login);
  }

  /**
   * Restablecer contraseña.
   * @param parameters
   * @returns
   */
  resetPassword(parameters): Observable<any> {
    return this.restClientService.post(`${this.URL}/reset`, parameters);
  }
}
