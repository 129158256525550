<div class="selectLanguage">
  <button class="mat-button-custom" mat-button [matMenuTriggerFor]="menu" [ngClass]="{ 'navbar-custom': !footer }">
    <span class="selector-text" [ngClass]="{ 'footer-text': footer }" [innerHTML]="'lenguage_Data1' | i18n"> {{"language_name" | i18n }} </span>
    <img src="/assets/images/navbar/arrow-down.svg" class="navbar-arrow" [ngClass]="{ ' footer-arrow': footer }" />
  </button>
  <mat-menu #menu="matMenu">
    <button [ngClass]="{ 'footer-language-option': footer, 'header-language-option': !footer }" mat-menu-item (click)="setCurrentLanguage('es')" [innerHTML]="'lenguage_Data2' | i18n">
    </button>
    <!-- <button [ngClass]="{ 'footer-language-option': footer, 'header-language-option': !footer }" mat-menu-item *ngIf="currentLanguage.code != 'es'" (click)="setCurrentLanguage('es')" [innerHTML]="'lenguage_Data2' | i18n">
    </button> -->
    <!-- <button [ngClass]="{ 'footer-language-option': footer, 'header-language-option': !footer }" mat-menu-item *ngIf="currentLanguage.code != 'en'" (click)="setCurrentLanguage('en')" [innerHTML]="'lenguage_Data3' | i18n">
    </button>
    <button [ngClass]="{ 'footer-language-option': footer, 'header-language-option': !footer }" mat-menu-item *ngIf="currentLanguage.code != 'pt'" (click)="setCurrentLanguage('pt')" [innerHTML]="'lenguage_Data4' | i18n"> -->
    <!-- </button> -->
    <!-- <button
      mat-menu-item
      *ngIf="currentLanguage.code != 'pt'"
      (click)="setCurrentLanguage('pt')">
      Português
    </button> -->
  </mat-menu>
</div>
