import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../core/services/api-access/rest-client.service';
import { Information } from '../models/information';

/**
 * Servicio para el acceso a la API de información
 */
@Injectable({
    providedIn: 'root'
})
export class InfoRemoteService {

  /**
   * Constructor
   * @ignore
   */
    constructor(private restClientService: RestClientService) { }

    /**
     * Método para obtener la información de la aplicación
     * @param name
     * @returns
     */
    public get(name: string): Observable<Information> {
        return this.restClientService.get(`${name}`);
    }
}
