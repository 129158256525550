import { Injectable } from '@angular/core';
import {RestClientService} from '../core/services/api-access/rest-client.service';

/**
 * Servicio de acceso a la API de thumbnails.
 */
@Injectable({
  providedIn: 'root'
})
export class ThumbnailRemoteService {
  /**
   * URL base del servicio.
   */
  private URL = '/projects';

  /**
   * Constructor.
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Crea el thumbnail de un video.
   * @param projectId
   * @returns
   */
  createVideoThumbnail(projectId) {
    return this.restClientService.get(this.URL + '/videos/' + projectId);
  }
}
