import {Injectable} from '@angular/core';

/**
 * Servicio de almacenamiento.
 */
@Injectable({
    providedIn: 'root',
})
export class StorageService {
  /**
   * Guarda un valor en el almacenamiento.
   * @param key
   * @param value
   */
    public set(key: string, value: string): void {
      // window.localStorage[key] = value;
      localStorage.setItem(key, value);
    }

    /**
     * Obtiene un valor del almacenamiento.
     * @param key
     * @param defaultValue
     * @returns
     */
    public get(key: string, defaultValue?: string): string {
      return localStorage.getItem(key) || defaultValue;
      // return window.localStorage[key] || defaultValue;
    }

    /**
     * Guarda un objeto en el almacenamiento.
     * @param key
     * @param value
     */
    public setObject(key: string, value: any): void {
      localStorage.setItem(key, JSON.stringify(value));
      // window.localStorage[key] = JSON.stringify(value);
    }

    /**
     * Obtiene un objeto del almacenamiento.
     * @param key
     * @returns
     */
    public getObject(key: string): any {
      return JSON.parse(localStorage.getItem(key));
      // return JSON.parse(window.localStorage[key] || null);
    }

    /**
     * Función para borrar un valor del almacenamiento.
     * @param key
     */
    public delete(key: string): any {
      localStorage.removeItem(key);
      // delete window.localStorage[key];
    }
}
