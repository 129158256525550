import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { User } from 'src/app/shared/models/user/user';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  constructor() { }

  /** Usuario logeado */
  private userSubject: ReplaySubject<any> = new ReplaySubject<any>(1);

  public setUser(user: any): void {
    this.userSubject.next(user);
  }

  public getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }
}
