import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {

  private readonly COOKIE_KEY = 'cookiesAccepted';

  constructor() {}

  isCookieAccepted(): boolean {
    console.log('pasa');
    return localStorage.getItem(this.COOKIE_KEY) === 'true';
  }

  acceptCookies() {
    localStorage.setItem(this.COOKIE_KEY, 'true');
  }
}
