<footer>
  <div id="footer">
    <div class="footer-mobile-container">
      <a class="footer-action" routerLink="/home" routerLinkActive="active-home">
        <img class="mobile-comun-img image-white" src="/assets/images/navbar/footer-action-home.svg" />
        <img class="mobile-comun-img image-orange active-home"
          src="/assets/images/navbar/footer-action-home-activated.svg" />
        <p class="footer-action-text" [innerHTML]=" 'footer_Data1' | i18n"><!-- {{ "home" | i18n }} --></p>
      </a>
      <a class="footer-action footer-action-new-project" routerLink="/new-project"
        routerLinkActive="active-new-proyect">
        <img class="mobile-comun-img image-white" src="/assets/images/navbar/footer-action-new3d.svg" />
        <img class="mobile-comun-img image-orange" src="/assets/images/navbar/footer-action-new3d-activated.svg" />
        <p class="footer-action-text" [innerHTML]=" 'footer_Data2' | i18n"><!-- {{ "newProject" | i18n }} --></p>
      </a>
      <a class="footer-action footer-action-gallery" routerLink="/gallery" routerLinkActive="active-gallery">
        <img class="mobile-comun-img image-white" src="/assets/images/navbar/footer-action-gallery.svg" />
        <img class="mobile-comun-img image-orange" src="/assets/images/navbar/footer-action-gallery-activated.svg" />
        <p class="footer-action-text" [innerHTML]=" 'footer_Data3' | i18n"><!-- {{ "gallery" | i18n }} --></p>
      </a>
      <a class="footer-action" routerLink="/profile/user-rates" routerLinkActive="active">
        <img class="mobile-comun-img image-white" src="/assets/images/navbar/footer-action-account.svg" />
        <p class="footer-action-text" [innerHTML]=" 'footer_Data4' | i18n"><!-- {{ "home_data1" | i18n }} --></p>
      </a>
    </div>
    <div class="white-footer">
      <img class="footer-logo" src="assets/images/footer/ecapturedtech_logo.svg" />
      <div class="white-footer-right-side">
        <div class="app-links">
          <div class="app-links-text" [innerHTML]=" 'footer_Data5' | i18n"><!-- {{ "downloadApp" | i18n }}: --></div>
          <a class="app-links-apple" href="https://apps.apple.com/es/app/eyescloud3d/id1479032117" target="__blank"><img
              class="app-links-img" src="assets/images/footer/app-store.png" /></a>
          <a class="app-links-google" href="https://play.google.com/store/apps/details?id=com.ecapture3d.eyescloud3d"
            target="__blank"><img class="app-links-img" src="assets/images/footer/google-play.png" /></a>
        </div>

        <div class="social-networks">
          <div class="social-networks-text" [innerHTML]=" 'footer_Data6' | i18n"><!-- {{ "followUsIn" | i18n }}: --></div>
          <a class="social-networks-link" href="https://www.linkedin.com/showcase/eyesnroad" target="__blank"><img
              class="social-networks-img" src="assets/images/footer/linkedin-social-network.svg" /></a>
          <a class="social-networks-link" href="https://www.facebook.com/eyesNroad/" target="__blank"><img
              class="social-networks-img" src="assets/images/footer/facebook-social-network.svg" /></a>
          <a class="social-networks-link" href="https://twitter.com/EyesNroad" target="__blank"><img
              class="social-networks-img" src="assets/images/footer/twitter-social-network.svg" /></a>
          <a class="social-networks-link social-networks-link-last" href="https://www.instagram.com/eyesnroad/?hl=es"
            target="__blank"><img class="social-networks-img"
              src="assets/images/footer/instagram-social-network.svg" /></a>
        </div>
        <app-language-selector [footer]="true" class="language-selector"></app-language-selector>
      </div>
    </div>



    <div class="blue-footer footer-terminos-uso">
      <div class="footer-text" [innerHTML]="footerText"> <!-- © {{ year }} {{ "footerText" | i18n }}. --> </div>

      <div class="footer-links-container">
        <a class="footer-links-text footer-links-terms-of-use" routerLink="/terms-of-use" [innerHTML]=" 'footer_Data8' | i18n "> <!-- {{ "termsOfUse" | i18n }} --></a>
        <div class="footer-divider"></div>
        <a class="footer-links-text footer-links-privacy" routerLink="/privacy-policy" [innerHTML]=" 'footer_Data9' | i18n "></a><!-- {{ "privacyPolicy" | i18n }} -->
        <div class="footer-divider"></div>
        <a class="footer-links-text footer-links-cookies" routerLink="/cookie-policy" [innerHTML]=" 'footer_Data10' | i18n "> </a><!-- {{ "cookiePolicies" | i18n }} -->

      </div>
    </div>

  </div>
</footer>