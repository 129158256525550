import {Component, EventEmitter, Input, Output} from '@angular/core';
import { Inspection } from 'src/app/models/inspections/inspection';

/**
 * Componente para mostrar un proyecto en el slider de proyectos populares
 */
@Component({
  selector: 'app-populars-item',
  templateUrl: './populars-item.component.html',
  styleUrls: ['./populars-item.component.scss']
})
export class PopularsItemComponent {

  /** Url del avatar */
  avatarSrc = '';
  /** Revisa si el icono de favoritos está marcado */
  favIconActive = false;

  /** Carga el proyecto */
  @Input() inspection: Inspection;
  /** Emite al dar click */
  @Output() itemClick = new EventEmitter<any>();

  /**
   * Emite el evento de click
   * @param code
   */
  click(code: number) {
    this.itemClick.emit(code);
  }

  /**
   * Pone la imagen por defecto
   */
  setDefaultImage(event) {
    event.target.src = '/assets/images/blue-background.png';
  }

  /**
   * Pone el avatar por defecto
   * @param event
   */
  setDefaultAvatar(event) {
    event.target.src = '/assets/images/gallery-item-default-avatar.png';
  }

  /**
   * Añade a favoritos
   */
  addToFav() {
    this.favIconActive = !this.favIconActive;
  }
}
