import { Injectable } from "@angular/core";

/**
 * Servicio responsive.
 */
@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  /**
   * Constructor.
   * @ignore
   */
  constructor() {
    window.onresize = this.callSubscribers;
  }

  /** Variable callbacks */
  private callbacks = [];

  /**
   * Gallery-home grids
   */
  private breakpoints = {
    // gallery-home grids
    landscape: "(orientation: landscape)",
    mobileG: "(max-width: 599px)",
    mobileGHorizontal: "(max-width: 1023px)",
    tabletG: "(min-width: 600px) and (max-width: 1279px)",
    tabletGHorizontal: "(min-width: 1024px) and (max-width: 1279px)",

    mobile: "(max-width: 414px)",
    tablet: "(min-width:415px) and (max-width:1279px)",
    pc: "(min-width: 1280px)",
    tips: "(max-width: 760px)",

    mobile414: "(min-width:413px) and (max-width:600px)",
    lessThen414: "(max-width:413px)",
    tablet836: "(min-width:834px) and (max-width:1023px)",
    tablet768: "(min-width: 768px)",
    tablet834: "(min-width: 834px)",
    s: "(max-width:627px)",
    m: "(min-width:600px) and (max-width:991px)",
    l: "(min-width:992px) and (max-width:1199px)",
    smr: "(min-width:830px) and (orientation: landscape) and (max-height: 600px) and (max-width: 1023px)",
    lmr: "(min-width:1024px) and (orientation: landscape) and (max-height: 672px)",
    xlmr: "(min-width:1194px) and (orientation: landscape) and (max-height: 766px)",
    more1024: "(min-width:1024px)",
    lessThen834: "(max-width:833px)",
    lessThen1024: "(max-width:1023px)",
    moreThan1194: "(min-width:1194px)",
    moreThan1536: "(min-width:1536px)",
    xl: "(min-width:1200px)",
    xxll: "(min-width: 1280px)",
    xll: "(min-width:1250px)",
    xmll: "(min-width:1600px)",
    xxl: "(min-width:1900px)",
    xsOrs: "(max-width:575px),(min-width:576px) and (max-width:767px)",
    mobile360: "(max-width:414px)",
  };

  /**
   * Establece el tamaño de la pantalla con la variable landscape.
   * @returns
   */
  landscape = () => this.ruleIsMet(this.breakpoints.landscape);
  /**
   * Establece el tamaño de la pantalla con la variable mobileG.
   * @returns
   */
  mobileG = () => this.ruleIsMet(this.breakpoints.mobileG);
  /**
   * Establece el tamaño de la pantalla con la variable mobileGHorizontal.
   * @returns
   */
  mobileGHorizontal = () => this.ruleIsMet(this.breakpoints.mobileGHorizontal);
  /**
   * Establece el tamaño de la pantalla con la variable tabletG.
   * @returns
   */
  tabletG = () => this.ruleIsMet(this.breakpoints.tabletG);
  /**
   * Establece el tamaño de la pantalla con la variable mobile.
   * @returns
   */
  tabletGHorizontal = () => this.ruleIsMet(this.breakpoints.tabletGHorizontal);
  /**
   * Establece el tamaño de la pantalla con la variable mobile.
   * @returns
   */
  lessThan414 = () => this.ruleIsMet(this.breakpoints.lessThen414);
  /**
   * Establece el tamaño de la pantalla con la variable mobile.
   * @returns
   */
  isMobile = () => this.ruleIsMet(this.breakpoints.mobile);
  /**
   * Establece el tamaño de la pantalla con la variable tablet.
   * @returns
   */
  isMobile414 = () => this.ruleIsMet(this.breakpoints.mobile414);
  /**
   * Establece el tamaño de la pantalla con la variable tablet.
   * @returns
   */
  isTablet = () => this.ruleIsMet(this.breakpoints.tablet);
  /**
   * Establece el tamaño de la pantalla con la variable pc.
   * @returns
   */
  isPC = () => this.ruleIsMet(this.breakpoints.pc);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  specialMovilResolution = () => this.ruleIsMet(this.breakpoints.smr);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  specialTabletResolution = () => this.ruleIsMet(this.breakpoints.lmr);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  specialTabletResolution2 = () => this.ruleIsMet(this.breakpoints.xlmr);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  lessThan1024 = () => this.ruleIsMet(this.breakpoints.lessThen1024);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  lessThan834 = () => this.ruleIsMet(this.breakpoints.lessThen834);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  moreThan1536 = () => this.ruleIsMet(this.breakpoints.moreThan1536);
  /**
   * Establece el tamaño de la pantalla con la variable tips.
   * @returns
   */
  public isTips = () => this.ruleIsMet(this.breakpoints.tips);
  /**
   * Establece el tamaño de la pantalla con la variable s.
   * @returns
   */
  public is836 = () => this.ruleIsMet(this.breakpoints.tablet836);
  /**
   * Establece el tamaño de la pantalla con la variable s.
   * @returns
   */
  public is768 = () => this.ruleIsMet(this.breakpoints.tablet768);
  /**
   * Establece el tamaño de la pantalla con la variable s.
   * @returns
   */
  public is834 = () => this.ruleIsMet(this.breakpoints.tablet834);
  /**
   * Establece el tamaño de la pantalla con la variable s.
   * @returns
   */
  public isS = () => this.ruleIsMet(this.breakpoints.s);
  /**
   * Establece el tamaño de la pantalla con la variable m.
   * @returns
   */
  public isM = () => this.ruleIsMet(this.breakpoints.m);
  /**
   * Establece el tamaño de la pantalla con la variable l.
   * @returns
   */
  public isl = () => this.ruleIsMet(this.breakpoints.l);
  /**
   * Establece el tamaño de la pantalla con la variable xl.
   * @returns
   */
  public isxl = () => this.ruleIsMet(this.breakpoints.xl);
  /**
   * Establece el tamaño de la pantalla con la variable xxll.
   * @returns
   */
  public moreThan1024 = () => this.ruleIsMet(this.breakpoints.more1024);
  /**
   * Establece el tamaño de la pantalla con la variable xxll.
   * @returns
   */
  public moreThan1194 = () => this.ruleIsMet(this.breakpoints.moreThan1194);
  /**
   * Establece el tamaño de la pantalla con la variable xxll.
   * @returns
   */
  public isxxll = () => this.ruleIsMet(this.breakpoints.xxll);
  /**
   * Establece el tamaño de la pantalla con la variable mobile360.
   * @returns
   */
  public isxll = () => this.ruleIsMet(this.breakpoints.xll);
  /**
   * Establece el tamaño de la pantalla con la variable xxll.
   * @returns
   */
  public isxmll = () => this.ruleIsMet(this.breakpoints.xmll);
  /**
   * Establece el tamaño de la pantalla con la variable xxll.
   * @returns
   */
  public isxxl = () => this.ruleIsMet(this.breakpoints.xxl);
  /**
   * Establece el tamaño de la pantalla con la variable xxll.
   * @returns
   */
  public isSmallScreen = () => this.ruleIsMet(this.breakpoints.xsOrs);

  /**
   * Método que registra un callback.
   * @param f
   */
  public registerChangeCallback = (f) => {
    this.callbacks.push(f);
  };

  /**
   * Revisa si cumple con la regla.
   * @param rule
   * @returns
   */
  private ruleIsMet = (rule: string) => window.matchMedia(rule).matches;

  /**
   * Método que llama a los suscriptores.
   * @returns
   */
  private callSubscribers = () => {
    const len = this.callbacks.length;

    if (len === 0) {
      return;
    }

    let i = 0;

    for (; i < len; i++) {
      this.callbacks[i]();
    }
  };

  /**
   * Método que se ejecuta cuando se cambia el tamaño de la pantalla.
   * @param data
   * @param dialogRef
   */
  public screenResize(data, dialogRef) {
    if (this.isMobile()) {
      // 360x600px (max-width: 414px)
      dialogRef.updateSize(data[0], data[1]);
    }

    if (this.isMobile414()) {
      // 414x732px (min-width:413px) and (max-width:600px)
      dialogRef.updateSize(data[2], data[3]);
    }

    if (this.isM()) {
      // 600x854 (min-width:600px) and (max-width:991px)
      dialogRef.updateSize(data[4], data[5]);
    }

    if (this.is768()) {
      // 768px (min-width:768px)
      dialogRef.updateSize(data[6], data[7]);
    }

    if (this.specialMovilResolution()) {
      // 830x436 (min-width: 830px) and (orientation: landscape) and (max-height: 600px)
      dialogRef.updateSize(data[8], data[9]);
    }

    if (this.is834()) {
      // 834x1126 (min-width: 834px)
      dialogRef.updateSize(data[10], data[11]);
    }

    if (this.moreThan1024()) {
      // 1024x672 (min-width: 1024px)
      dialogRef.updateSize(data[12], data[13]);
    }

    if (this.moreThan1194()) {
      // 1194x766 (min-width: 1194px)
      dialogRef.updateSize(data[14], data[15]);
    }

    if (this.isxxll()) {
      // 1280x700 (min-width: 1280px)
      dialogRef.updateSize(data[16], data[17]);
    }

    if (this.moreThan1536() || this.isxmll()) {
      // 1536x754 (min-width:1536px)
      dialogRef.updateSize(data[18], data[19]);;
    }

    if (this.isxxl()) {
      // 1920x969 (min-width:1900px)
      dialogRef.updateSize(data[20], data[21]);
    }
  }
}
