import {Injectable} from '@angular/core';
import {RestClientService} from '../../api-access/rest-client.service';
import {Observable} from 'rxjs';
import {Credit} from '../../../../models/payment/credit';

/**
 * Servicio para los créditos
 */
@Injectable({
  providedIn: 'root'
})
export class CreditsRemoteService {

  /**
   * Devuelve la url de la API
   */
  private URL = 'credits';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Devuelve los créditos del usuario
   * @returns
   */
  getUserCredits(): Observable<Credit> {
    return this.restClientService.get(`${this.URL}/user-credits`);
  }
}
