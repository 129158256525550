import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {I18nPipe} from './pipes/i18n.pipe';
import {I18nDirective} from './directives/i18n.directive';
import {AngularMaterialModule} from './modules/angular-material.module';
import {RouterModule} from '@angular/router';
import {SplitPipe} from './pipes/split.pipe';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtendedModule} from '@angular/flex-layout';

@NgModule({
  declarations: [
    I18nPipe,
    I18nDirective,
    SplitPipe,
  ],
  imports: [
    AngularMaterialModule,
  ],
  exports: [
    CommonModule,
    RouterModule,
    I18nPipe,
    I18nDirective,
    AngularMaterialModule,
    SplitPipe,
    FormsModule,
    ReactiveFormsModule,
    ExtendedModule,
  ]
})
export class SharedModule { }
