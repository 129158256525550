<div class="two-factor-dialog-container">
    <div class="header">
      <p class="title">Verificación en dos pasos</p>
      <p class="sub-title">Para proteger tu cuenta, eyesCloud3d quiere verificar <br>
        que eres tu quién está intentanto iniciar sesión</p>
    </div>

    <input class="token-input" [ngClass]="{'token-input-error': badToken}" autocomplete="off" type="text" placeholder="{{user.email}}"
    id="two-factor-token" required minlength="10" [(ngModel)]="inputToken">
    <p class="error-msg" *ngIf="badToken">La clave introducida no es válida</p>
    <div class="send-button" (click)="send()">
      <p>Enviar</p>
    </div>

    <div class="login-options">
      <p>Elige cómo quieres iniciar sesión:</p>
    </div>

    <div class="options-container">
      <div class="phone-verification" (click)="phoneVerification()">
        <img src="../../../../../assets/images/auth/two-factor/phone-verification-ico.svg">
        <p *ngIf="!noPhone">Recibe un <span>código de verificación</span> en el número *** ** ** <span>{{lastDigits}}.</span></p>
        <p *ngIf="noPhone">No tienes añadido ningún número de teléfono.</p>
      </div>

      <div class="line"></div>

      <div class="account-recovery" (click)="accountRecovery()">
        <img src="../../../../../assets/images/auth/two-factor/account-recovery-ico.svg">
        <p>O prueba la <span>recuperación de la cuenta.</span></p>
      </div>
    </div>

    <div class="user-terms-container">
      <p><a routerLink="/terms-of-use">Términos de uso</a></p>
      <div class="small-line"></div>
      <p><a routerLink="/privacy-policy">Política de privacidad</a></p>
      <div class="small-line"></div>
      <p><a routerLink="/cookie-policy">Política de cookies</a></p>
    </div>
</div>
