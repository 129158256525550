import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

/**
 * Servicio para mostrar el loader
 */
@Injectable({
    providedIn: 'root',
})
export class LoaderService {
  /**
   * Variable para mostrar el loader
   */
  private loading = new BehaviorSubject<boolean>(false);

  /**
   * Muestra el loader
   */
  public showLoader(): void {
    this.loading.next(true);
  }

  /**
   * Oculta el loader
   */
  public hideLoader(): void {
    this.loading.next(false);
  }

  /**
   * Devuelve el estado del loader
   * @returns
   */
  public isLoading(): Observable<boolean> {
    return this.loading;
  }
}
