import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../api-access/rest-client.service';
import {Rate} from '../../../shared/models/model/rate';

/**
 * Servicio para las tarifas
 */
@Injectable({
    providedIn: 'root'
})
export class RateRemoteService {

  /**
   * Devuelve la url de la API
   */
  private URL = '/rates';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Devuelve las tarifas
   * @returns
   */
  public search(): Observable<Rate[]> {
      return this.restClientService.get(`${this.URL}`);
  }

  /**
   * Devuelve la tarifa
   * @param id
   * @returns
   */
  public get(id: number): Observable<Rate> {
    return this.restClientService.get(`${this.URL}/all`);
  }
}
