import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {Thumbnail} from '../../../models/thumbnail';

/** Componente para mostrar un slider de imágenes */
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  /** Variable a false */
  isDown = false;
  /** variable startX */
  startX: number;
  /** variable para el scroll izquierdo */
  scrollLeft: any;
  /** Variable de comienzo */
  start: any;
  /** Variable de fin */
  end: any;

  /** Mostrat slider */
  @ViewChild('slider', { static: true }) slider: ElementRef;
  /** Imágenes del slider */
  @Input() images: Thumbnail[];
  /** Emite evento al llegar al final */
  @Output() arrivedToEnd = new EventEmitter();
  /** Emite evento al dar click */
  @Output() clickItem = new EventEmitter<boolean>();

  /**
   * Constructor
   * @param elementRef
   */
  constructor(private elementRef: ElementRef) {}

  /**
   * Listener para el documento
   * @param event
   */
  @HostListener('document:click', ['$event'])
  /**
   * Si contiene event.target pone la variable a false
   */
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isDown = false;
    }
  }

  /**
   * Función para el mouseDown
   * @param event
   */
  mouseDown(event) {
    this.isDown = true;
    this.slider.nativeElement.classList.add('active');
    this.startX = event.pageX - this.slider.nativeElement.offsetLeft;
    this.scrollLeft = this.slider.nativeElement.scrollLeft;
    this.start = +new Date();
  }

  /**
   * Función para el mouseUp
   * @param event
   */
  mouseUp(event) {
    this.isDown = false;
    this.slider.nativeElement.classList.remove('active');
    this.end = +new Date();
    const diff = this.end - this.start;
    this.clickItem.emit(diff < 100);
  }

  /**
   * Función para el mouseMove
   * @param event
   * @returns
   */
  mouseMove(event) {
    if (!this.isDown) { return; }
    event.preventDefault();
    const x = event.pageX - this.slider.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 2; // scroll-fast
    this.slider.nativeElement.scrollLeft = this.scrollLeft - walk;
  }
}
