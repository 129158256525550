import * as moment_ from 'moment';
import {Injectable} from '@angular/core';
const moment = moment_;

/**
 * Servicio para el manejo de fechas
 */
@Injectable({
    providedIn: 'root',
})
export class DateService {
  /**
   * Método para obtener la fecha actual
   * @returns
   */
    static currentDate(): Date {
        return moment().toDate();
    }

    /**
     * Método para obtener rangos de fechas
     * @param date
     * @param startDate
     * @param endDate
     * @returns
     */
    static dateIsPresentInRange(date: Date, startDate: Date, endDate: Date): boolean {
        return DateService.dateIsSameOrAfter(date, startDate) && DateService.dateIsSameOrBefore(date, endDate);
    }

    /**
     * Conseguir un string de la fecha actual slice 0,10
     * @param date
     * @returns
     */
    static getIsoStringDatePart(date: Date): string {
        return date.toISOString().slice(0, 10);
    }

    /**
     * Llama a la función de formato de fecha
     * @param date
     * @returns
     */
    static getIsoString(date: Date): string {
        return date.toISOString();
    }

    /**
     * Le da formato a la fecha
     * @param dateIsoString
     * @returns
     */
    static getDateFromIsoString(dateIsoString: string | Date): Date {
        return moment(dateIsoString, 'YYYY-MM-DD').toDate();
    }

    /**
     * Método para clonar una fecha
     * @param date
     * @returns
     */
    static cloneDate(date: Date): Date {
        return moment(date).toDate();
    }

    /**
     * Comprueba si la fecha es anterior
     * @param date1
     * @param date2
     * @returns
     */
    static dateIsBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isBefore(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    /**
     * Comprueba si el momento es anterior
     * @param date1
     * @param date2
     * @returns
     */
    static datetimeIsBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(date1).isBefore(date2);
    }

    /**
     * Comprueba si es igual o anterior
     * @param date1
     * @param date2
     * @returns
     */
    static dateIsSameOrBefore(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isSameOrBefore(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    /**
     * Si la fecha es posterior
     * @param date1
     * @param date2
     * @returns
     */
    static dateIsAfter(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isAfter(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    /**
     * Revisa si es igual o posterior
     * @param date1
     * @param date2
     * @returns
     */
    static dateIsSameOrAfter(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isSameOrAfter(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    /**
     * Compara que dos fechas son iguales
     * @param date1
     * @param date2
     * @returns
     */
    static dateIsSame(date1: Date|string, date2: Date|string): boolean {
        return moment(DateService
          .getIsoStringDatePart(DateService.getDate(date1)))
          .isSame(DateService.getIsoStringDatePart(DateService.getDate(date2)));
    }

    /**
     * Añade días a la fecha
     * @param date
     * @param days
     * @returns
     */
    static addDays(date: Date, days: number): Date {
        return moment(date).add(days, 'days').toDate();
    }

    /**
     * Añade milisegundos a la fecha
     * @param date
     * @param milliseconds
     * @returns
     */
    static addMilliseconds(date: Date, milliseconds: number): Date {
        return moment(date).add(milliseconds, 'milliseconds').toDate();
    }

    /**
     * Saca días de la fecha
     * @param date
     * @param days
     * @returns
     */
    static subtractDays(date: Date, days: number): Date {
        return moment(date).subtract(days, 'days').toDate();
    }

    /**
     * Añade meses a la fecha
     * @param date
     * @param months
     * @returns
     */
    static addMonths(date: Date, months: number): Date {
        return moment(date).add(months, 'month').toDate();
    }

    /**
     * Da formato a la fecha
     * @param date
     * @param format
     * @param locale
     * @returns
     */
    static formatDate(date: Date|string, format: string = 'DD-MM-YYYY', locale: string = 'en'): string {
        return moment(date).locale(locale).format(format);
    }

    /**
     * Obtiene fecha anterior o otra
     * @param date
     * @param locale
     * @returns
     */
    static getTimeAgoStringFromDate(date: Date, locale: string): string {
        return moment(date).locale(locale).fromNow();
    }

    /**
     * Saca el string completo de una fecha
     * @param date
     * @param locale
     * @returns
     */
    static getFullDateStringFromDate(date: Date, locale: string): string {
        return moment(date).toDate().toLocaleString(locale);
    }

    /**
     * Compara noches entre fechas
     * @param startDate
     * @param endDate
     * @returns
     */
    static nightsBetweenDates(startDate: Date, endDate: Date): number {
        return moment(endDate).diff(moment(startDate), 'days');
    }

    /**
     * Obtiene la primera del mes
     * @returns
     */
    static getFirstDateOfMonthIsoString(): Date {
        return moment().startOf('month').toDate();
    }

    /**
     * Obtiene la última del mes
     * @returns
     */
    static getEndDateOfMonthIsoString(): Date {
        return moment().endOf('month').toDate();
    }

    /**
     * Obtiene fecha
     * @param date
     * @returns
     */
    static getDate(date: Date| string): Date {
        return typeof date === 'string' ? new Date(date) : date;
    }

    /**
     * Da formato
     * @param date
     * @param format
     * @returns
     */
    formatDateTime(date: Date| string, format: string = 'YYYY-MM-DD HH:mm:ss'): string {
        return DateService.formatDate(typeof date === 'string' ? new Date(date) : date, format);
    }
}
