import { Injectable } from '@angular/core';
import { Retrievable } from '../../shared/models/retrievable';
import { Observable } from 'rxjs';
import {RestClientService} from '../../core/services/api-access/rest-client.service';
import {Configuration} from '../../models/common/configuration';

/**
 * Servicio para la gestión de la configuración de la aplicación
 */
@Injectable({
  providedIn: 'root'
})
export class ConfigurationRemoteService implements Retrievable<Configuration, string> {

  /**
   * Obtiene la URL del servicio
   */
  private URL = '/configurations';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Obtiene la configuración de la aplicación
   * @param key
   * @returns
   */
  public get(key: string): Observable<Configuration> {
    return this.restClientService.get(`${this.URL}/${key}`);
  }
}
