import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { _ } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { PaymentRemoteService } from 'src/app/core/services/remote/payment/payment-remote.service';
import { RedsysRemoteService } from 'src/app/core/services/remote/payment/redsys-remote.service';
import { ProjectRemoteService } from 'src/app/core/services/remote/project/project-remote.service';
import { AuthenticationService } from 'src/app/services/security/authentication.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { Payment } from 'src/app/shared/models/model/payment';
import { Rate } from 'src/app/shared/models/model/rate';
import { RedsysCreateParams } from 'src/app/shared/models/redsys/redsys-create-params';
import { RedsysResponse } from 'src/app/shared/models/redsys/redsys-response';
import { RedsysSubscriptionResponse } from 'src/app/shared/models/redsys/redsys-subscription-response';
import { User } from 'src/app/shared/models/user/user';
import { CreditsRemoteService } from '../../../../core/services/remote/payment/credits-remote.service';
import { RateRemoteService } from '../../../../core/services/remote/rate-remote.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';

/** Componente de exceso de km */
@Component({
  selector: 'app-km-excess-dialog',
  templateUrl: './km-excess-dialog.component.html',
  styleUrls: ['./km-excess-dialog.component.scss']
})
export class KmExcessDialogComponent {
  /** Evitar que el mensaje se muestre de nuevo */
  notShowAgain = false;
  /** variable para el formato */
  formatOption = 0;
  /** Id de la tarifa del usuario */
  userRateId: number = undefined;
  /** Próxima tarifa a null por defecto */
  nextRate: Rate = null;
  /** Tarifa del usuario */
  kmRate: Rate;
  /** Id del proyecto */
  projectId: number;
  /** Usuario actual */
  user: User;
  /** Cantidad de km excedidos */
  exceededKms: number;
  /** formulario de pago */
  formData: RedsysSubscriptionResponse;
  /** elemento de formulario a partir de RedsysResponse */
  @ViewChild('form') form: ElementRef;

  /**
   * Constructor
   * @ignore
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<KmExcessDialogComponent>,
    private storageService: StorageService,
    private creditsRemoteService: CreditsRemoteService,
    private rateRemoteService: RateRemoteService,
    private projectRemoteService: ProjectRemoteService,
    private responsiveService: ResponsiveService,
    private redsysRemoteService: RedsysRemoteService,
    private authenticationService: AuthenticationService,
    private paymentRemoteService: PaymentRemoteService,
    private router: Router) {
    this.projectId = this.data;
    this.create();
  }
  /** Evento que se ejecuta al ser creado el componente para redimensionar el popup */
  ngOnInit(){
    this.tamSizePopUp();
  }

  /**
   * Guarda en el local storage que el usuario no quiere volver a ver el mensaje de exceso de kms
   * @param notShowAgain
   * @param formatOption
   */
  onAcceptClick(): void {
    if (this.notShowAgain) {
      this.storageService.set('roadDialog', this.formatOption.toString());
    }
    this.dialogRef.close(this.formatOption);
  }

  /** Tipo seleccionado */
  selectType(selection) {
    this.formatOption = selection;
  }

  /** Evitar que se muestre el mensaje de nuevo */
  notShowMessage(event) {
    this.notShowAgain = event.target.checked;
  }

  /**
   * Revisa si tiene créditos disponibles para pagar el exceso de kms y si no, muestra las diferentes tarifas.
   * @param credit
   * @param rates
   * @param userRate
   */
  create(): void {
    this.creditsRemoteService.getUserCredits().subscribe(credit => {
      if (credit != null) {
        this.userRateId = credit.rate_id;
        this.rateRemoteService.get(credit.rate_id).subscribe(userRate => {
          if (userRate != null) {
            this.rateRemoteService.search().subscribe(rates => {
              rates.sort((a, b) => this.compareTwoRates(a, b)); // Se ordenan las suscripciones de menos a más KMs ofrecidos
              rates.forEach(rate => {
                if (this.nextRate === null && rate.kms > userRate.kms) {
                  this.nextRate = rate; // Establecemos la siguiente suscripción a la que tiene el usuario, y ya no volverá a entrar aquí
                }
                if (rate.name === 'EXCESO KM') { // Tarifa que especifica el caso de Kilómetros excedidos
                  this.kmRate = rate;
                  this.authenticationService.get().subscribe(user => {
                    this.user = user;
                    this.projectRemoteService.getCostPerExceededKm(this.projectId).subscribe(kms => { // Obtengo el número de km excedidos
                      this.exceededKms = kms;
                      this.prepareRedsysParams();
                    });
                  });
                }
              })
            })
          }
        })
      }

    });
  }

  /** Compara dos tarifas */
  compareTwoRates(a: Rate, b: Rate): number {
    return (a.kms - b.kms);
  }

  /** Descarta los vídeos en proceso y te lleva a home*/
  discardVideos() {
    this.projectRemoteService.discardExceededKmVideos(this.projectId).subscribe(() =>{
      this.dialogRef.close(this.formatOption);
      this.router.navigateByUrl('/home');
    });
  }

  /**
   * Genera el formulario para la creación de la suscripción en Redsys
   * @returns
   */
  goToPayRedsys() {
    if (!this.formData) { return; }
    const payment: Payment = {
      user_id: this.user.id,
      rate_id: this.kmRate.id,
      amount: parseFloat((this.exceededKms * this.kmRate.price).toFixed(2)),
      payment_source_id: 1,
      order_number: this.formData.orderNumber,
      project_id: this.projectId
    };
    this.paymentRemoteService.add(payment).subscribe(() => {
      this.form.nativeElement.submit();
    });
  }

  /**
   * Prepara los parámetros para la creación de la suscripción en Redsys
   */
  private prepareRedsysParams() {
    const redsysParams: RedsysCreateParams = {
      rateId: this.kmRate.id,
      userId: this.user.id,
      price: parseFloat((this.exceededKms * this.kmRate.price).toFixed(2))
    };
    this.redsysRemoteService.createMerchantParameters(redsysParams).subscribe((data: RedsysResponse) => {
      this.formData = data;
    });
  }

  /** Cierra el popup  */
  onNoClick(): void {
    this.dialogRef.close(false);
  }

  /** Redimensiona el tamaño del Pop-Up en función del tamaño de la pantalla */
  tamSizePopUp() {

    if (this.responsiveService.isMobile() || this.responsiveService.isMobile414()) {
    this.dialogRef.updateSize('302px', '448px');
    }

    if (this.responsiveService.isM()) {
      this.dialogRef.updateSize('376px','544px');
    }

    if (this.responsiveService.is768()) {
      this.dialogRef.updateSize('650px','300px');
    }

    if (this.responsiveService.specialMovilResolution()) {
      this.dialogRef.updateSize('540px','252px');
    }

    if (this.responsiveService.is834()) {
      this.dialogRef.updateSize('652px','300px');
    }

    if (this.responsiveService.moreThan1024()) {
      this.dialogRef.updateSize('652px','300px');
    }

    if (this.responsiveService.moreThan1194()) {
      this.dialogRef.updateSize('756px','341px');
      
    }

    if (this.responsiveService.isxxll()) {
      this.dialogRef.updateSize('800px','368px');
    }

    if (this.responsiveService.moreThan1536() || this.responsiveService.isxmll()) {
      this.dialogRef.updateSize('742px','336px');
    }

    if (this.responsiveService.isxxl()) {
      this.dialogRef.updateSize('875px','400px');
    }

  }

}
