import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../core/services/api-access/rest-client.service';
import {AcceptedLogin} from '../../models/security/accepted-login';
import {BaseProfile} from '../../models/security/base-profile';

/**
 * Servicio para el acceso a la API de autenticación
 */
@Injectable({ providedIn: 'root' })
export class EmailVerifiedRemoteService {
  /**
   * URL base de la API
   */
  private URL = '/email-verified';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Método para verificar el token de autentificación
   * @param token
   * @returns
   */
  verify<T extends BaseProfile>(token): Observable<AcceptedLogin<T>> {
    return this.restClientService.get(`${this.URL}/${token}`);
  }
}
