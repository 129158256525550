import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-content',
  templateUrl: './information-content.component.html',
  styleUrls: ['./information-content.component.scss']
})
export class InformationContentComponent implements OnInit {
  @Input() informationType: string;

  constructor() { }

  ngOnInit(): void {
    console.log(this.informationType);
  }

}
