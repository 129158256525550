import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestClientService} from '../../api-access/rest-client.service';
import {RedsysCreateParams} from '../../../../shared/models/redsys/redsys-create-params';
import {RedsysResponse} from '../../../../shared/models/redsys/redsys-response';
import {RedsysSubscriptionCreateParams} from '../../../../shared/models/redsys/redsys-subscription-create-params';
import {RedsysSubscriptionResponse} from '../../../../shared/models/redsys/redsys-subscription-response';

/**
 * Servicio para la integración con Redsys
 */
@Injectable({
  providedIn: 'root'
})
export class RedsysRemoteService {

  /**
   * Devuelve la url de la API
   */
  private URL = 'redsys';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) { }

  /**
   * Crea los parámetros para la integración con Redsys
   * @param redsysCreateParams
   * @returns
   */
  createMerchantParameters(redsysCreateParams: RedsysCreateParams): Observable<RedsysResponse> {
    return this.restClientService.post(`${this.URL}/create-parameters`, redsysCreateParams);
  }

  /**
   * Revisa los parámetros de la respuesta de Redsys
   * @param redsysParam
   * @returns
   */
  checkMerchantParameters(redsysParam): Observable<string> {
    return this.restClientService.post(`${this.URL}/checkParameters`, redsysParam);
  }

  /**
   * Crea los parámetros de la suscripción para la integración con Redsys
   * @param redsysCreateParams
   * @returns
   */
  createSubscriptionMerchantParameters(redsysCreateParams: RedsysSubscriptionCreateParams): Observable<RedsysSubscriptionResponse> {
    return this.restClientService.post(`${this.URL}/create-subscription-parameters`, redsysCreateParams);
  }
}
