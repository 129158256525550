<div class="cookies-dialog-content">
    <div class="icon-close" (click)="noAcceptCookies()">
        <p>X</p>
    </div>
    <h2><p [innerHTML]="'cookies_Data1' | i18n"></p></h2>

    <div class="text-content">
        <p>
        <span [innerHTML]="'cookies_Data2' | i18n"></span>
        <span [innerHTML]="'cookies_Data3' | i18n"></span>
        </p>
    </div>
    <div class="buttons">
        <div class="first-buttons">
            <div class="go-to-cookies-policy" (click)="goToCookiesPolicy()">
                <p [innerHTML]="'cookies_Data4' | i18n"></p>
            </div>
        </div>
        <div class="last-buttons">
            <div class="accept-dialog-button" (click)="acceptCookies()">
                <p [innerHTML]="'cookies_Data5' | i18n"></p>
            </div>
            <div class="no-accept-dialog-button" (click)="noAcceptCookies()">
                <p [innerHTML]="'cookies_Data6' | i18n"></p>
            </div>
        </div>
    </div>
</div>
