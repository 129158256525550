/**
 * Configuración del idioma
 */
export class Language {
  /**
   * Constructor
   * @ignore
   */
  constructor(public code: string,
              public name: string,
              public httpHeader: string) {}
}
