import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
// import {Project} from '../../../models/projects/project';
import { Inspection } from 'src/app/models/inspections/inspection';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { I18nService } from 'src/app/core/services/i18n.service';
import { TranslationModeEnum } from '../../enum/translation-mode-enum';

/** Componente de la galeria de proyectos */
@Component({
  selector: 'app-gallery-grid',
  templateUrl: './gallery-grid.component.html',
  styleUrls: ['./gallery-grid.component.scss']
})
export class GalleryGridComponent implements OnInit, OnChanges {

  /**
   * Constructor del componente.
   * @ignore
   */
  constructor(private router: Router,
              private i18nService: I18nService) {
              }

  /** Establece el valor del input de búsqueda. */
  searchValue = '';
  /** Obtiene las inspecciones del usuario */
  searchInspections: Inspection[] = [];
  /** Inspecciones a mostrar */
  inspectionsToShow: Inspection[] = [];
  /** Variable para el debouncer */
  debouncer: Subject<string> = new Subject<string>();
  /** Variable para el texto de búsqueda. */
  searchTitle: string;
  /** Texto cuando encuentra proyectos */
  searchTitleFound: string;

  /** Carga las inspecciones */
  @Input() inspections: Inspection[] = [];
  /** Por defecto no muestra la búsqueda */
  @Input() showSearch = false;
  /** Por defecto no salen los anuncios */
  @Input() showGoogleAdsense = false;
  /** Porcentaje del grid */
  @Input() gridItemPercent: number;
  /** Emite evento al filtrar */
  @Output() filterChanged = new EventEmitter<string>();

  /**
   * Función que se ejecuta al iniciar el componente.
   */
  ngOnInit() {
    this.debouncer.pipe(debounceTime(300)).subscribe(value => {
      this.searchValue = value.toLowerCase();
      this.searchInspections = [];
      this.searchInspections = Object.assign([], this.inspections);
      this.searchInspections = this.searchInspections.filter(inspection => inspection.name.toLowerCase().includes(this.searchValue));
      this.searchTitle = this.i18nService.getText('galeria_Data11', [TranslationModeEnum.INSERT_VALUE, this.searchValue]);
      this.searchTitleFound = this.i18nService.getText('galeria_Data9', [TranslationModeEnum.INSERT_VALUE, this.searchValue]);
    });
  }

  /**
   * Cantidad de proyectos que se muestran en el grid.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('inspections') && !changes.inspections.isFirstChange()) {
      this.inspectionsToShow = [];
      if (this.inspections.length > 32) {
        this.inspectionsToShow = this.inspections.slice(0, 32);
      } else {
        this.inspectionsToShow = Object.assign([], this.inspections);
      }
    }
  }

  /**
   * Te lleva a la página del proyecto.
   * @param code
   */
  onSliderClick(code: string) {
    this.router.navigate(['/inspections', code]);
  }

  /**
   * Cambia el valor del input de búsqueda.
   * @param value
   */
  filterChange(value: string) {
    this.debouncer.next(value);
  }

  /**
   * Cierra la búsqueda.
   */
  closeSearch() {
    this.searchValue = '';
    this.searchInspections = [];
  }

  /**
   * Encuentra el proyecto según la búsqueda.
   * @param index
   * @param item
   * @returns
   */
  trackBySearcher(index: number, item: Inspection): number {
    return item.id;
  }
}
