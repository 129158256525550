import {Injectable} from '@angular/core';
import {AppConfig} from '../../core/models/config/app-config';

/**
 * Servicio para la configuración de la aplicación
 */
@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    /**
     * Configuración de la aplicación
     */
    private appConfig: AppConfig;
    /**
     * Configuración de la aplicación sin caché
     */
    private appConfigWithoutCache: AppConfig;

    /**
     * Constructor
     */
    constructor() {}

    /**
     * Método para obtener la configuración de la aplicación
     * @returns
     */
    public getAppConfig(): AppConfig {
        return this.appConfig;
    }

    /**
     * Método para obtener la configuración de la aplicación sin caché
     * @param appConfig
     */
    public setAppConfig(appConfig: AppConfig): void {
        this.appConfig = appConfig;
    }
}
