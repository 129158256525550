import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-project-error-dialog',
  templateUrl: './project-error-dialog.component.html',
  styleUrls: ['./project-error-dialog.component.scss']
})
export class ProjectErrorDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ProjectErrorDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeDialog() {
     this.dialogRef.close();
  }

}
