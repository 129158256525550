import {Component, Input, OnInit} from '@angular/core';
import { I18nService } from 'src/app/core/services/i18n.service';

/** Componente footer */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  /** Obtiene el año */
  year = new Date().getFullYear();
  /** Texto del footer */
  footerText:string;

  /**
   * Constructor
   * @param i18nService Servicio de internacionalización
   */
  constructor( private i18nService: I18nService) {

  }

  /** Inicializa el componente */
  ngOnInit() {
    this.footerText = "© "+ this.year +" "+ this.i18nService.getText('footer_Data7')
  }

}
