<div class="navbar-container">
  <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/navbar/blue/logo-navbar.png"
    *ngIf="backgroundBlue" />
  <img routerLink="/home" class="eyesnroad-navbar-img" src="/assets/images/navbar/white/logo-navbar.png"
    *ngIf="!backgroundBlue" />

  <div class="eyescloud-navbar" [ngClass]="{
      'navbar-background-blue': backgroundBlue,
      'navbar-background-white': !backgroundBlue
    }">

    <div *ngIf="((actualRoute !== '/terms-of-use' && actualRoute !== '/privacy-policy' && actualRoute !== '/cookie-policy') || isLogged)">
      <img routerLink="/home" class="eyesnroad-logo-img" src="/assets/images/ecapturedtech-logo.svg"
        *ngIf="backgroundBlue" />
      <img routerLink="/home" class="eyesnroad-logo-img display-pc" src="/assets/images/ecapturedtech-normal.svg"
        *ngIf="!backgroundBlue" />
    </div>

    <div *ngIf="((actualRoute === '/terms-of-use') || (actualRoute === '/privacy-policy') || (actualRoute === '/cookie-policy')) && !isLogged">
      <img routerLink="/login" class="eyesnroad-logo-img" src="/assets/images/ecapturedtech-logo.svg"
        *ngIf="backgroundBlue" />
      <img routerLink="/login" class="eyesnroad-logo-img display-pc" src="/assets/images/ecapturedtech-normal.svg"
        *ngIf="!backgroundBlue" />
    </div>

    <a routerLink="/profile/personal-data" class="navbar-menu-link">
      <img class="img-menu" src="/assets/images/navbar/blue/menu-icon.png" *ngIf="backgroundBlue" />
      <img class="img-menu" src="/assets/images/navbar/white/menu-icon.png" *ngIf="!backgroundBlue" />
    </a>
    <div class="eyescloud-navbar-menu">
      <a class="text-link text-link-first" [ngClass]="{ 'text-link-blue': !backgroundBlue }" routerLink="/new-project"
        [innerHTML]="'navbar_Data1' | i18n" *ngIf="((actualRoute !== '/terms-of-use' && actualRoute !== '/privacy-policy' && actualRoute !== '/cookie-policy') || isLogged)">
        <!-- {{ "newProject" | i18n }} -->
      </a>
      <a class="text-link text-link-mid" [ngClass]="{ 'text-link-blue': !backgroundBlue }" routerLink="/gallery"
        [innerHTML]="'navbar_Data2' | i18n" *ngIf="((actualRoute !== '/terms-of-use' && actualRoute !== '/privacy-policy' && actualRoute !== '/cookie-policy') || isLogged)">
        <!-- {{ "gallery" | i18n }} -->
      </a>
      <a (click)="onEventEmit()" class="text-link text-link-last" [ngClass]="{ 'text-link-blue': !backgroundBlue }"
        routerLink="/profile/personal-data" [innerHTML]="'navbar_Data3' | i18n" *ngIf="((actualRoute !== '/terms-of-use' && actualRoute !== '/privacy-policy' && actualRoute !== '/cookie-policy') || isLogged)">
        <!-- {{ "account" | i18n }} -->
      </a>
      <!-- ! fin text -->
      <div class="line-separator" [ngClass]="{ 'line-separator-blue': !backgroundBlue }" *ngIf="((actualRoute !== '/terms-of-use' && actualRoute !== '/privacy-policy' && actualRoute !== '/cookie-policy') || isLogged)"></div>
      <app-language-selector [footer]="false" class="language-selector"></app-language-selector>
      <div class="line-separator" [ngClass]="{ 'line-separator-blue': !backgroundBlue }"></div>
      <img class="logout-icon" src="/assets/images/logout-icon.svg" (click)="logout()" *ngIf="((actualRoute !== '/terms-of-use' && actualRoute !== '/privacy-policy' && actualRoute !== '/cookie-policy') || isLogged)"/>
      <a (click)="chatSwitcher()" class="chat-online-button" [ngClass]="{ 'chat-online-button-blue': !backgroundBlue }"
        [innerHTML]="'navbar_Data4' | i18n">
        <!-- {{ chatTitle }} -->
      </a>
    </div>
  </div>
</div>