import {Observable} from 'rxjs';
import {Searchable} from '../../../../shared/models/searchable';
import {User} from '../../../../shared/models/user/user';
import {Retrievable} from '../../../../shared/models/retrievable';
import {UserSearchCriteria} from '../../../../shared/models/user/user-search-criteria';
import {Saveable} from '../../../../shared/models/saveable';
import {Deleteable} from '../../../../shared/models/deleteable';
import {RestClientService} from '../../api-access/rest-client.service';
import {ChangePasswordRequest} from '../../../../profile/models/change-password-request';
import {Project} from '../../../../models/projects/project';
import { Inspection } from 'src/app/models/inspections/inspection';
import {UserData} from '../../../../profile/models/user-data';
import {Injectable} from '@angular/core';

/**
 * Servicio para los usuarios
 */
@Injectable()
export class UserRemoteService implements
        Searchable<User, UserSearchCriteria>,
        Retrievable<User, number>,
        Saveable<User>,
        Deleteable<User, number> {

  /**
   * Devuelve la url de la API
   */
  private URL = '/users';

  /**
   * Constructor
   * @ignore
   */
  constructor(private restClientService: RestClientService) {}

  /**
   * Devuelve los usuarios
   * @param criteria
   * @returns
   */
  public search(criteria?: UserSearchCriteria): Observable<User[]> {
      return this.restClientService.get(this.URL, criteria);
  }

  /**
   * Devuelve el usuario
   * @param id
   * @returns
   */
  public get(id: number): Observable<User> {
      return this.restClientService.get(`${this.URL}/profile/${id}`);
  }

  /**
   * Obtiene los usuarios del departamento
   * @param id
   * @returns
   */
  public getDepartmentUsers(id: number): Observable<User[]> {
      return this.restClientService.get(`${this.URL}/${id}/department-users`);
  }

  /**
   * Registra un usuario
   * @param user
   * @returns
   */
  public save(user: User): Observable<any> {
      if (user.id !== undefined && user.id !== null) {
          return this.restClientService.put(`${this.URL}/${user.id}`, user);
      }
      return this.restClientService.post('register', user);
  }

  /**
   * Borra un usuario
   * @param id
   * @returns
   */
  public delete(): Observable<User> {
      return this.restClientService.delete(`${this.URL}`);
  }

  /**
   * Restablece la contraseña
   * @param id
   * @param password
   * @returns
   */
  public resetPassword(id: number, password: string): Observable<User> {
      return this.restClientService.put(`${this.URL}/${id}/password`, {password, id});
  }

  /**
   * Actualiza la contraseña
   * @param currentPassword
   * @param newPassword
   * @returns
   */
  public updatePassword(currentPassword: string, newPassword: string): Observable<User> {
      return this.restClientService.put(`${this.URL}/password`, {currentPassword, newPassword});
  }

  /**
   * Función para restaurar la contraseña
   * @param login
   * @param confirmationLink
   * @returns
   */
  public forgotPassword(login: string, confirmationLink: string) {
    return this.restClientService.put(`${this.URL}/forgot-password/${login}`, {confirmationLink});
  }

  /**
   * Sube un avatar
   * @param userId
   * @param avatar
   * @returns
   */
  public uploadAvatar(userId: number, formData: FormData): Observable<{avatar: string}> {
    return this.restClientService.post(`${this.URL}/${userId}/avatar`, formData);
  }

  /**
   * Obtiene la url del avatar
   * @param userId
   * @returns
   */
  public getAvatarUrl(userId: number): Observable<string> {
    return this.restClientService.get(`${this.URL}/${userId}/avatar`);
  }

  /**
   * Cambia la contraseña
   * @param user
   * @param changePasswordRequest
   * @returns
   */
  public changePassword(user: User, changePasswordRequest: ChangePasswordRequest) {
    return this.restClientService.put(`${this.URL}/${user.id}/change-password`, changePasswordRequest);
  }

  /**
   * Obtiene las inspecciones del usuario
   * @param countInspections
   * @returns
   */
  public getInspections(countInspections?: number): Observable<any> {
    const request = {
      per_page: countInspections
    };
    return this.restClientService.get(`${this.URL}/inspections`, request);
  }

  /**
   * Obtiene la información del usuario
   * @returns
   */
  public userData(): Observable<any> {
    return this.restClientService.get(`${this.URL}/user-data`, null);
  }

  /**
   * Obtiene los Kms disponibles
   * @returns
   */
  public getKms(): Observable<any> {
    return this.restClientService.get(`${this.URL}/kms`, null);
  }


}
