import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '../../core/services/loader.service';
// import { Project } from "src/app/models/projects/project";
// import { ProjectThumbnail } from "src/app/models/project-thumbnail";
import { Inspection } from 'src/app/models/inspections/inspection';
import { InspectionThumbnail } from 'src/app/models/inspection-thumbnail';
import { UserRemoteService } from '../../core/services/remote/user/user.remote.service';
import { ResponsiveService } from 'src/app/core/services/responsive.service';
import { I18nService } from 'src/app/core/services/i18n.service';
import { TranslationModeEnum } from 'src/app/commons/enum/translation-mode-enum';

/** Componente para la página de inicio */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  [x: string]: any;

  /**
   * Constructor de la clase.
   * @ignore
   */
  constructor(
    private router: Router,
    private i18nService: I18nService,
    private loaderService: LoaderService,
    private userRemoteService: UserRemoteService,
    private responsiveService: ResponsiveService
  ) {}

  userInspections: Inspection[] = [];
  sliderImages: InspectionThumbnail[];
  isMobile = false;

  ngOnInit() {
    this.loaderService.showLoader();
    this.getUserInspections();
    this.isMobile = window.innerWidth < 600;
  }

  private getUserInspections() {
    this.userRemoteService.getInspections(10).subscribe(
      (inspections) => {
        this.userInspections = inspections;
        if (this.userInspections.length > 10) {
          this.userInspections = this.userInspections.slice(0, 10);
        }
        this.loadSliderImages();
        this.loaderService.hideLoader();
      },
      (error) => this.loaderService.hideLoader()
    );
  }

  private loadSliderImages() {
    this.sliderImages = [];
    this.userInspections.forEach((inspection) => {
      this.sliderImages.push({
        name: inspection.name,
        src: inspection.preview,
        inspection,
      });
    });
  }

  /** Navega a la página de creación de proyecto. */
  gotToNewInspection() {
    this.loaderService.showLoader();
    this.router.navigateByUrl('new-project');
  }
}
