<div class="project-error-dialog-content">
    <div class="icon-close" (click)="closeDialog()">
        <p>X</p>
    </div>
    <h2>¡ERROR DE IMPORTACIÓN!</h2>

    <div class="text-content">
        <p>
            Los videos subidos no se corresponden con la
            telemetría asociada
        </p>
        <p>Por favor, revise los ficheros proporcionados</p>

    </div>
    <div class="error-dialog-button">
        <p>Aceptar</p>
    </div>
</div>

