import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {SecurityTokenStorage} from '../security-token-storage';
import {AuthenticationService} from '../authentication.service';
import {UserToken} from '../../../models/security/user-token';

/**
 * Guard para proteger la ruta del inicio de sesión
 */
@Injectable({
    providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  /**
   * Constructor
   * @ignore
   */
    constructor(private authenticationService: AuthenticationService,
                private securityTokenStorage: SecurityTokenStorage<UserToken>,
                private router: Router) {}

    /**
     * Método que se ejecuta al intentar acceder a la ruta
     * @param activatedRouteSnapshot
     * @param state
     * @returns
     */
    public canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const identity = this.securityTokenStorage.getObjectValue() ? this.securityTokenStorage.getObjectValue() : null;
        if (identity) {
          return true;
        }
        this.router.navigateByUrl(AuthenticationService.getAuthenticationUrl());
        return false;
    }
}
